import { useState } from "react";
import { cantactUsApi } from "../../api";
import toast from "toastr";
import { useNavigate } from "react-router-dom";

const ContactUsPage = () => {
  toast.options = { preventDuplicates: true };
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setErrors({});
  };

  const validate1 = () => {
    const newErrors = {};
    if (!data?.name) {
      newErrors.name = "Name is required";
    } else if (!data?.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data?.email)) {
      newErrors.email = "Please enter a valid email address";
    } else if (!data?.subject) {
      newErrors.subject = "Subject is required";
    } else if (!data?.message) {
      newErrors.message = "Message is required";
    } else if (!data?.mobileNumber) {
      newErrors.mobileNumber = "Mobile number is required";
    } else if (!/^\d{10}$/.test(data?.mobileNumber)) {
      newErrors.mobileNumber = "Please enter a valid Mobile number";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate1()) {
        const obj = {
          name: data?.name,
          email: data?.email,
          countryCode: "+91",
          mobile: data?.mobileNumber,
          message: data?.message,
        };
        setLoader(true);
        const res = await cantactUsApi(obj);
        if (res?.status === 200) {
          toast.success(res?.data?.message);
          setLoader(false);
          setData({}); // Clear the form fields
        } else if (res?.data?.error_description?.statusCode === 401) {
          toast.error(res?.data?.error_description?.message);
          setLoader(false);
          localStorage.clear();
          navigate("/login");
        } else {
          setLoader(false);
        }
      }
    } catch (err) {
      setLoader(false);
    }
  };

  return (
    <div>
      <p className="text-3xl  text-primary font-bold text-center w-full ">
        Contact Us
      </p>

      <div className="my-12">
        <div className="container">
          <div className="row flex-wrap-reverse">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <div>

            
                {/* <div className="open-hours mt-4">
                  <i className="icon-clock-o"></i>
                  <h4 className="mb-2">Open Hours:</h4>
                  <p>
                    Sunday-Friday:
                    <br />
                    11:00 AM - 23:00 PM
                  </p>
                </div> */}

                <div className="d-flex feature-h">
                  <span className="wrap-icon me-3 hw-50">
                    <span className="icon-envelope"></span>
                  </span>
                  <div className="feature-text">
                    <h3 className="heading">Email</h3>
                    <p className="text-black-50">
                      info@sharebric.com
                    </p>
                  </div>
                </div>

                <div className="d-flex feature-h">
                  <span className="wrap-icon me-3 hw-50">
                    <span className="icon-phone"></span>
                  </span>
                  <div className="feature-text">
                    <h3 className="heading">Call</h3>
                    <p className="text-black-50">
                      +91 0000000000
                    </p>
                  </div>
                </div>

                <div className="d-flex feature-h">
                  <span className="wrap-icon me-3 hw-50">
                    <span className="icon-room"></span>
                  </span>
                  <div className="feature-text">
                    <h3 className="heading">Location</h3>
                    <p className="text-black-50">
                      SY:11 WeWork Krishe Emerald, Kondapur Main Road, Laxmi Cyber
                      City, Whitefields, Serilingampally Mandal, Ranga Reddy
                      District, Hyderabad, Telangana 500081
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-lg-8">
              <form>
                <div className="row">
                  <div className="col-12 col-sm-6 mb-3">
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Name</label>
                    <input
                      type="text"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Enter Name"
                      name="name"
                      required
                      value={data?.name || ""}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <span className="text-red-500 text-xs">{errors.name}</span>
                    )}
                  </div>

                  <div className="col-12 col-sm-6 mb-3">
                    <label for="mobileNumber" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mobile Number (Optional)</label>
                    <input
                      type="tel"
                      id="mobileNumber"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="1234-56-7890" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      name="mobileNumber"
                      value={data?.mobileNumber || ""}
                      onChange={handleChange}
                    />
                    {errors.mobileNumber && (
                      <span className="text-red-500 text-xs">
                        {errors.mobileNumber}
                      </span>
                    )}
                  </div>

                  <div className="col-12 mb-3">
                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Email</label>
                    <input
                      type="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Enter Email"
                      name="email"
                      value={data?.email || ""}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span className="text-red-500 text-xs">{errors.email}</span>
                    )}
                  </div>



                  <div className="col-12 mb-3">
                    <label for="subject" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Subject</label>
                    <input
                      type="text"
                      id="subject"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Enter Subject"
                      name="subject"
                      value={data?.subject || ""}
                      onChange={handleChange}
                    />
                    {errors.subject && (
                      <span className="text-red-500 text-xs">{errors.subject}</span>
                    )}
                  </div>

                  <div className="col-12 mb-3">
                    <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Message</label>
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="4"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Enter Something..."
                      value={data?.message || ""}
                      onChange={handleChange}
                    ></textarea>
                    {errors.message && (
                      <span className="text-red-500 text-xs">{errors.message}</span>
                    )}
                  </div>

                  <div className="col-12 mb-8">
                    <button
                      disabled={loader}
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-primary py-2 px-3 rounded-2"
                    >
                      {loader ? "Sending..." : "Send Message"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUsPage;
