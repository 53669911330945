import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getPropertyApi, notificationApi } from "../../api";
import toast from "toastr";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { formatDate, currancyFormat } from "../../utils/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import NotificationFormModal from "../../components/Modals/notificationFormModal";


const CustomNextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      position: "absolute",
      right: "-25px",
      top: "50%",
      transform: "translateY(-50%)",
      backgroundColor: "transparent",
      border: "none",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      cursor: "pointer",
      zIndex: 1,
    }}
  >
    <img
      src="/images/next.png"
      alt="Next"
      style={{ width: "32px", height: "32px" }}
    />
  </button>
);

const CustomPrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      position: "absolute",
      left: "-25px",
      top: "50%",
      transform: "translateY(-50%)",
      backgroundColor: "transparent",
      border: "none",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      cursor: "pointer",
      zIndex: 1,
    }}
  >
    <img
      src="/images/back (2).png"
      alt="Previous"
      style={{ width: "32px", height: "32px" }}
    />
  </button>
);

const settings = {
  dots: false,
  infinite: false,
  speed: 2000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const settings1 = {
  dots: false,
  infinite: false,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 500,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const HomePage = () => {
  toast.options = { preventDuplicates: true };
  const [loader, setLoader] = useState(false);
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();
  const [propertyDetails, setPropertyDetails] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetchProperties();
  }, []);

  const fetchProperties = async (pageNumber) => {
    try {
      setLoader(true);
      const response = await getPropertyApi({ page: pageNumber });
      console.log("response::", response);
      if (response?.status == 200) {
        setLoader(false);
        setProperties(response.data?.data);
      } else if (response?.data?.error_description?.statusCode == 401) {
        toast.error(response?.data?.error_description?.message);
        setLoader(false);
        localStorage.clear();
        navigate("/login");
      } else {
        setLoader(false);
        console.error("Failed to fetch data:", response);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };
  const handleNotificationSubmit = async (property) => {
    let localUserData = localStorage.getItem("userInfo");
    if (localUserData) {
      localUserData = JSON.parse(localUserData);
    }

    setLoader(true);

    try {
      const response = await notificationApi({
        name: localUserData.userName,
        email: localUserData.email,
        phone: localUserData.mobile,
        propertyId: property?.id,
      });
      if (response?.status === 200) {
        toast.success(response.data?.message);
      } else {
        toast.error("Failed to subscribe notification!");
      }
    } catch (error) {
      toast.error("Something want wrong!");
    } finally {
      setLoader(false);
    }
  };


  return (
    <>
      {loader && <Loader />}
      <div className="section py-4">
        <div className="container">
          <div className="d-flex align-items-center justify-between">
            <h2 className="font-bold text-primary text-xl">
              Popular Properties
            </h2>
            <a
              href="/properties"
              className="btn btn-primary py-2 px-3 rounded-3"
            >
              View all properties
            </a>
          </div>
        </div>
      </div>
      <div
        className={`container mb-12 ${properties.length <= 3 ? "items-start" : "items-center"
          }`}
      >
        {!loader && properties && properties.length > 0 ? (
          <div className="row">
            {properties.length <= 3 ? (
              properties.map((val) => (
                <div
                  key={val.id}
                  className="col-xs-12 col-sm-6 col-md-6 col-lg-4"
                >
                  <a href={`/property-details?id=${val?.id}`} className="img">
                    <div className="features-1 rounded-lg p-6">
                      {/* <span className="flaticon-house"></span> */}
                      <Slider {...settings1}>
                        {val?.images.map((val1) => {
                          return (
                            <img
                              src={val1}
                              alt="Image"
                              className="h-[240px] object-cover w-full rounded-lg"
                            />
                          );
                        })}
                      </Slider>
                      <div className="text-start">
                        {/* <p className="property-title mt-4">{val.name}</p> */}
                        <p className="property-title mt-4">
                          {val.name}
                          {new Date(val.launchingDate) > new Date() && (
                            <FontAwesomeIcon
                              icon={faBell}
                              className="w-6 h-6 cursor-pointer text-[#EA9832] float-right"
                              onClick={(e) => {
                                e.preventDefault();
                                if (localStorage.getItem("userInfo")) {
                                  handleNotificationSubmit(val);
                                } else {
                                  setPropertyDetails(val);
                                  setModalOpen(true);
                                }
                              }}
                            />
                          )}
                        </p>
                        <div className="row">
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <span>Each Bric Cost</span>
                            <p className="text-primary font-bold">
                              {currancyFormat(val.eachBricCost)}
                            </p>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <span>Launch Date</span>
                            <p className="text-primary font-bold">
                              {formatDate(val.launchingDate)}
                            </p>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <span>Status</span>
                            <p className="status-badge">
                              {val.availableBrics === 0 ? (
                                <span className="badge bg-danger">
                                  Sold Out
                                </span>
                              ) : new Date(val.launchingDate) > new Date() ? (
                                <span className="badge bg-warning">
                                  Coming Soon
                                </span>
                              ) : (
                                <span className="badge bg-success">
                                  Available
                                </span>
                              )}
                            </p>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <span>Available</span>
                            <p className="text-primary font-bold">
                              {val.availableBrics}
                            </p>
                          </div>
                        </div>
                        {val.location ? (
                          <p>
                            {val.location.length > 50
                              ? val.location.substring(0, 50) + "..."
                              : val.location}
                          </p>
                        ):(<p>-</p>)}
                      </div>
                      <div className="property-status col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                        {/* Progress Bar Section */}
                        <div className="col-12">
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <span className="text-primary font-bold">
                              Brics Sold
                            </span>
                            <span className="text-sm font-medium text-gray-600">
                              {val.totalBrics - val.availableBrics}/
                              {val.totalBrics} Brics
                            </span>
                          </div>

                          <div className="w-full bg-gray-200 rounded-full h-3 relative">
                            <div
                              className="bg-green-500 h-3 rounded-full transition-all ease-out duration-300"
                              style={{
                                width: `${((val.totalBrics - val.availableBrics) /
                                  val.totalBrics) *
                                  100
                                  }%`,
                              }}
                            />
                            <div className="absolute inset-0 flex justify-center items-center text-gray-500 font-bold">
                              {Math.round(
                                ((val.totalBrics - val.availableBrics) /
                                  val.totalBrics) *
                                100
                              )}
                              %
                            </div>
                          </div>
                        </div>
                      </div>
                      <a
                        href={`/property-details?id=${val?.id}`}
                        className="learn-more"
                      >
                        See details
                      </a>
                    </div>
                  </a>
                </div>
              ))
            ) : (
              <Slider {...settings} className="w-full">
                {properties.map((val) => (
                  <div
                    key={val.id}
                    className="col-xs-12 col-sm-6 col-md-6 col-lg-4 px-2"
                  >
                    <a href={`/property-details?id=${val?.id}`} className="img">
                      <div className="features-1 rounded-lg p-6">
                        {/* <span className="flaticon-house"></span> */}
                        <Slider {...settings1}>
                          {val?.images.map((val1) => {
                            return (
                              <img
                                src={val1}
                                alt="Image"
                                className="h-[240px] object-cover w-full rounded-lg"
                              />
                            );
                          })}
                        </Slider>
                        <div className="text-start">
                          <p className="property-title mt-4">
                            {val.name}
                            {new Date(val.launchingDate) > new Date() && (
                              <FontAwesomeIcon
                                icon={faBell}
                                className="w-6 h-6 cursor-pointer text-[#EA9832] float-right"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (localStorage.getItem("userInfo")) {
                                    handleNotificationSubmit(val);
                                  } else {
                                    setPropertyDetails(val);
                                    setModalOpen(true);
                                  }
                                }}
                              />
                            )}
                          </p>
                          <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                              <span>Each Bric Cost</span>
                              <p className="text-primary font-bold">
                                ₹
                                {val.eachBricCost
                                  ? val.eachBricCost.toFixed(2)
                                  : "0"}
                              </p>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                              <span>Launch Date</span>
                              <p className="text-primary font-bold">
                                {formatDate(val.launchingDate)}
                              </p>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                              <span>Status</span>
                              <p className="status-badge">
                                {val.availableBrics === 0 ? (
                                  <span className="badge bg-danger">
                                    Sold Out
                                  </span>
                                ) : new Date(val.launchingDate) > new Date() ? (
                                  <span className="badge bg-warning">
                                    Coming Soon
                                  </span>
                                ) : (
                                  <span className="badge bg-success">
                                    Available
                                  </span>
                                )}
                              </p>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                              <span>Available</span>
                              <p className="text-primary font-bold">
                                {val.availableBrics}
                              </p>
                            </div>
                          </div>
                          {val.location ? (
                          <p>
                            {val.location.length > 50
                              ? val.location.substring(0, 50) + "..."
                              : val.location}
                          </p>
                        ):(<p>-</p>)}
                        </div>
                        <div className="property-status col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4">
                          {/* Progress Bar Section */}
                          <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <span className="text-primary font-bold">
                                Brics Sold
                              </span>
                              <span className="text-sm font-medium text-gray-600">
                                {val.totalBrics - val.availableBrics}/
                                {val.totalBrics} Brics
                              </span>
                            </div>

                            <div className="w-full bg-gray-200 rounded-full h-3 relative">
                              <div
                                className="bg-green-500 h-3 rounded-full transition-all ease-out duration-300"
                                style={{
                                  width: `${((val.totalBrics - val.availableBrics) /
                                    val.totalBrics) *
                                    100
                                    }%`,
                                }}
                              />
                              <div className="absolute inset-0 flex justify-center items-center text-gray-500 font-bold">
                                {Math.round(
                                  ((val.totalBrics - val.availableBrics) /
                                    val.totalBrics) *
                                  100
                                )}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                        <a
                          href={`/property-details?id=${val?.id}`}
                          className="learn-more"
                        >
                          See details
                        </a>
                      </div>
                    </a>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        ) : loader ? (
          ""
        ) : (
          <div className="col-12 text-center">No properties found.</div>
        )}
      </div>

      <section className="features-1">
        <div className="container">
          <div className="row">
            <div className="col-6 col-lg-3">
              <div className="box-feature">
                <span className="flaticon-house"></span>
                <h3 className="mb-3">Empowering Wealth Building</h3>
                <p>
                  We're transforming wealth creation through innovative real
                  estate investing, making it straightforward and accessible for
                  everyone.
                </p>
                <p>
                  <a href="/about" className="learn-more">
                    Learn More
                  </a>
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="box-feature">
                <span className="flaticon-building"></span>
                <h3 className="mb-3">Accessible Real Estate Investing</h3>
                <p>
                  Our mission is to unlock the wealth-building potential of
                  rental and vacation properties by simplifying the process and
                  lowering entry costs.
                </p>
                <p>
                  <a href="/about" className="learn-more">
                    Learn More
                  </a>
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="box-feature">
                <span className="flaticon-house-3"></span>
                <h3 className="mb-3">Maximizing Returns</h3>
                <p>
                  We focus on data-driven decisions and collaborations with
                  local experts, acquiring high-quality properties and managing
                  them with exceptional care.
                </p>
                <p>
                  <a href="/about" className="learn-more">
                    Learn More
                  </a>
                </p>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="box-feature">
                <span className="flaticon-house-1"></span>
                <h3 className="mb-3">Profits and Peace of Mind</h3>
                <p>
                  Our efficient operations and economies of scale ensure maximum
                  returns, delivering profits and peace of mind for our
                  investors.
                </p>
                <p>
                  <a href="/about" className="learn-more">
                    Learn More
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="section sec-testimonials">
        <div className="container">
          <div className="row mb-5 align-items-center">
            <div className="col-md-6">
              <h2 className="font-weight-bold heading text-primary mb-4 mb-md-0">
                Customer Says
              </h2>
            </div>
            <div className="col-md-6 text-md-end">
              <div id="testimonial-nav">
                <span className="prev" data-controls="prev">
                  Prev
                </span>

                <span className="next" data-controls="next">
                  Next
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4"></div>
          </div>
          <div className="testimonial-slider-wrap">
            <div className="flex flex-row testimonial-slider">
              <div className="item">
                <div className="testimonial">
                  <img
                    src="images/person_1-min.jpg"
                    alt="Image"
                    className="img-fluid rounded-circle w-25 mb-4"
                  />
                  <div className="rate">
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                  </div>
                  <h3 className="h5 text-primary mb-4">James Smith</h3>
                  <blockquote>
                    <p>
                      &ldquo;Far far away, behind the word mountains, far from
                      the countries Vokalia and Consonantia, there live the
                      blind texts. Separated they live in Bookmarksgrove right
                      at the coast of the Semantics, a large language
                      ocean.&rdquo;
                    </p>
                  </blockquote>
                  <p className="text-black-50">Designer, Co-founder</p>
                </div>
              </div>

              <div className="item">
                <div className="testimonial">
                  <img
                    src="images/person_2-min.jpg"
                    alt="Image"
                    className="img-fluid rounded-circle w-25 mb-4"
                  />
                  <div className="rate">
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                  </div>
                  <h3 className="h5 text-primary mb-4">Mike Houston</h3>
                  <blockquote>
                    <p>
                      &ldquo;Far far away, behind the word mountains, far from
                      the countries Vokalia and Consonantia, there live the
                      blind texts. Separated they live in Bookmarksgrove right
                      at the coast of the Semantics, a large language
                      ocean.&rdquo;
                    </p>
                  </blockquote>
                  <p className="text-black-50">Designer, Co-founder</p>
                </div>
              </div>

              <div className="item">
                <div className="testimonial">
                  <img
                    src="images/person_3-min.jpg"
                    alt="Image"
                    className="img-fluid rounded-circle w-25 mb-4"
                  />
                  <div className="rate">
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                  </div>
                  <h3 className="h5 text-primary mb-4">Cameron Webster</h3>
                  <blockquote>
                    <p>
                      &ldquo;Far far away, behind the word mountains, far from
                      the countries Vokalia and Consonantia, there live the
                      blind texts. Separated they live in Bookmarksgrove right
                      at the coast of the Semantics, a large language
                      ocean.&rdquo;
                    </p>
                  </blockquote>
                  <p className="text-black-50">Designer, Co-founder</p>
                </div>
              </div>

              <div className="item">
                <div className="testimonial">
               
                  <div className="rate">
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                    <span className="icon-star text-warning"></span>
                  </div>
                  <h3 className="h5 text-primary mb-4">Dave Smith</h3>
                  <blockquote>
                    <p>
                      &ldquo;Far far away, behind the word mountains, far from
                      the countries Vokalia and Consonantia, there live the
                      blind texts. Separated they live in Bookmarksgrove right
                      at the coast of the Semantics, a large language
                      ocean.&rdquo;
                    </p>
                  </blockquote>
                  <p className="text-black-50">Designer, Co-founder</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="section section-4 bg-light">
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-lg-5">
              <h2 className="font-weight-bold heading text-primary mb-4">
                Real Estate Reigns Supreme
              </h2>
              <p className="text-black-50">
                Diversify your portfolio and minimize risk with stress-free real
                estate investments, offering consistent returns without the
                usual hassles.
              </p>
            </div>
          </div>
          <div className="row justify-content-between pb-5">
            <div className="col-lg-7 mb-5 mb-lg-0 order-lg-2">
              <div className="img-about">
                <img
                  src="images/hero_bg_3.jpg"
                  alt="Image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex feature-h">
                <span className="wrap-icon me-3">
                  <span className="icon-home2"></span>
                </span>
                <div className="feature-text">
                  <h3 className="heading">Steady Passive Income</h3>
                  <p className="text-black-50">
                    Earn rental income with quarterly deposits, creating
                    additional revenue streams to help you achieve your
                    financial objectives and build long-term wealth.
                  </p>
                </div>
              </div>

              <div className="d-flex feature-h">
                <span className="wrap-icon me-3">
                  <span className="icon-person"></span>
                </span>
                <div className="feature-text">
                  <h3 className="heading">Property Value Growth</h3>
                  <p className="text-black-50">
                    Observe your investment increase in value as the property
                    appreciates, offering the potential for capital gains and
                    the ability to leverage your investment.
                  </p>
                </div>
              </div>

              <div className="d-flex feature-h">
                <span className="wrap-icon me-3">
                  <span className="icon-security"></span>
                </span>
                <div className="feature-text">
                  <h3 className="heading">Flexible Investment Options</h3>
                  <p className="text-black-50">
                    Invest at a level that suits your financial situation,
                    making real estate investment accessible to a broader range
                    of investors.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Modal */}
          <NotificationFormModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            propertyDetails={propertyDetails}
          />
          {/* <div className="row section-counter mt-5">
            <div className="col-6 col-sm-6 col-md-6 col-lg-3">
              <div className="counter-wrap mb-5 mb-lg-0">
                <span className="number">
                  <span className="countup text-primary">3298</span>
                </span>
                <span className="caption text-black-50">
                  # of Buy Properties
                </span>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3">
              <div className="counter-wrap mb-5 mb-lg-0">
                <span className="number">
                  <span className="countup text-primary">2181</span>
                </span>
                <span className="caption text-black-50">
                  # of Sell Properties
                </span>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3">
              <div className="counter-wrap mb-5 mb-lg-0">
                <span className="number">
                  <span className="countup text-primary">9316</span>
                </span>
                <span className="caption text-black-50">
                  # of All Properties
                </span>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-3">
              <div className="counter-wrap mb-5 mb-lg-0">
                <span className="number">
                  <span className="countup text-primary">7191</span>
                </span>
                <span className="caption text-black-50"># of Agents</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <div className="section">
        <div className="row justify-content-center footer-cta">
          <div className="col-lg-7 mx-auto text-center">
            <h2 className="mb-4">Be a part of our growing real state agents</h2>
            <p>
              <a
                href="#"
                target="_blank"
                className="btn btn-primary text-white py-3 px-4"
              >
                Apply for Real Estate agent
              </a>
            </p>
          </div>
        </div>
      </div> */}

      {/* <div className="section section-5 bg-light">
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-lg-6 mb-5">
              <h2 className="font-weight-bold heading text-primary mb-4">
                Our Agents
              </h2>
              <p className="text-black-50">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam
                enim pariatur similique debitis vel nisi qui reprehenderit
                totam? Quod maiores.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-4 mb-5 mb-lg-0">
              <div className="h-100 person">
                <img
                  src="images/person_1-min.jpg"
                  alt="Image"
                  className="img-fluid"
                />

                <div className="person-contents">
                  <h2 className="mb-0">
                    <a href="#">James Doe</a>
                  </h2>
                  <span className="meta d-block mb-3">Real Estate Agent</span>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Facere officiis inventore cumque tenetur laboriosam, minus
                    culpa doloremque odio, neque molestias?
                  </p>

                  <ul className="social list-unstyled list-inline dark-hover">
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="icon-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="icon-facebook"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="icon-linkedin"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="icon-instagram"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4 mb-5 mb-lg-0">
              <div className="h-100 person">
                <img
                  src="images/person_2-min.jpg"
                  alt="Image"
                  className="img-fluid"
                />

                <div className="person-contents">
                  <h2 className="mb-0">
                    <a href="#">Jean Smith</a>
                  </h2>
                  <span className="meta d-block mb-3">Real Estate Agent</span>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Facere officiis inventore cumque tenetur laboriosam, minus
                    culpa doloremque odio, neque molestias?
                  </p>

                  <ul className="social list-unstyled list-inline dark-hover">
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="icon-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="icon-facebook"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="icon-linkedin"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="icon-instagram"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-4 mb-5 mb-lg-0">
              <div className="h-100 person">
                <img
                  src="images/person_3-min.jpg"
                  alt="Image"
                  className="img-fluid"
                />

                <div className="person-contents">
                  <h2 className="mb-0">
                    <a href="#">Alicia Huston</a>
                  </h2>
                  <span className="meta d-block mb-3">Real Estate Agent</span>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Facere officiis inventore cumque tenetur laboriosam, minus
                    culpa doloremque odio, neque molestias?
                  </p>

                  <ul className="social list-unstyled list-inline dark-hover">
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="icon-twitter"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="icon-facebook"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="icon-linkedin"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="#">
                        <span className="icon-instagram"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default HomePage;
