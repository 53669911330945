import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash,faHome } from "@fortawesome/free-solid-svg-icons";
import { signinApi } from "../../api";
import toast from "toastr";
import Loader from "../../components/Loader";
const LoginPage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const [data, setData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const handlePasswordToggle = () => setShowPassword(!showPassword);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setErrors({});
  };

  const validate = () => {
    const newErrors = {};
    if (!data.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      newErrors.email = "Please enter a valid email address";
    } else if (!data.password.trim()) {
      newErrors.password = "Password is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        const obj = {
          email: data?.email,
          password: data?.password,
        };
        setLoader(true);
        const res = await signinApi(obj);
        if (res?.status == 200) {
          setLoader(false);
          localStorage.setItem("token", res?.data?.data?.accessToken);
          localStorage.setItem("userInfo", JSON.stringify(res?.data?.data));
          toast.success(res?.data?.message);
          console.log("Login successful:", data);
          navigate(-1); // or the appropriate route after login
        } else {
          setLoader(false);
          toast.error(res?.data?.message);
        }
      }
    } catch (err) {
      setLoader(false);
      throw err;
    }
  };

  return (
    <div className="bg-[#005555]  w-full h-screen flex flex-col justify-center align-center items-center">
      <div className="w-[350px] md:w-[400px] lg:w-[500px] bg-white rounded-lg shadow py-2 md:px-6 md:py-6">
        {loader && <Loader />}
        <h2 className="text-2xl text-black text-center w-full font-bold mt-4">
          Login
        </h2>
        <form onSubmit={handleSubmit} className="w-full h-full px-8 pt-8">
          <div className="flex flex-col text-md mb-2">
            <label className="text-black text-sm mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={data.email}
              onChange={handleChange}
              className="w-full text-sm px-2 py-2 bg-gray-200 border-none rounded-md"
              placeholder="Email"
            />
            {errors.email && (
              <p className="text-red-500 text-xs">{errors.email}</p>
            )}
          </div>
          <div className="flex flex-col text-md mb-2 relative">
            <label className="text-black text-sm mb-2">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={data.password}
              onChange={handleChange}
              className="w-full text-sm px-2 py-2 bg-gray-200 border-none rounded-md"
              placeholder="Password"
            />
            <span
              className="absolute right-3 top-9 cursor-pointer"
              onClick={handlePasswordToggle}
            >
              <FontAwesomeIcon
                className="text-black "
                icon={showPassword ? faEyeSlash : faEye}
              />
            </span>
            {errors.password && (
              <p className="text-red-500 text-xs">{errors.password}</p>
            )}
          </div>
          <div className="w-full">
            <p className="w-full text-end text-black text-sm">
              <u
                className="cursor-pointer"
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                Forgot Password?
              </u>
            </p>
          </div>
          <div className="w-full flex item-center justify-center mt-8">
            <button
              type="submit"
              disabled={loader ? true : false}
              className="bg-[#005555] px-2 py-2 text-white rounded-md w-full"
            >
              Login
            </button>
          </div>
          <div className="w-full flex item-center justify-center mt-4">
            <button
              type="button"
              className="text-[#005555] border border-[#005555] px-2 py-2 rounded-md w-full"
              onClick={() => {
                navigate("/register");
              }}
            >
              Create New Account
            </button>
          </div>
          {/* <div className="w-full">
            <p className="w-full text-center text-black text-sm my-4">
              <u
                className="cursor-pointer"
                onClick={() => {
                  navigate(-1);
                }}
              >
                GO to Home
              </u>
            </p>
          </div> */}
          <div className="w-full mt-6 flex items-center justify-center">
            <button
              className="flex items-center gap-2 px-4 py-2 bg-[#005555] text-white rounded-full shadow-md hover:bg-[#007777] transition-all duration-300"
              onClick={() => navigate("/")}
            >
              <FontAwesomeIcon icon={faHome} className="text-lg" />
              <span className="text-sm font-medium">Go to Home</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
