import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

const SIPCalculator = () => {
    const navigate = useNavigate();
    const [investment, setInvestment] = useState(25000);
    const [returnRate, setReturnRate] = useState(12);
    const [timePeriod, setTimePeriod] = useState(10);
    const [mode, setMode] = useState("SIP");

    const investedAmount = mode === "SIP" ? investment * timePeriod * 12 : investment;

    const totalValue = mode === "SIP" ? investment *
        (((Math.pow(1 + returnRate / 100 / 12, timePeriod * 12) - 1) / (returnRate / 100 / 12)) * (1 + returnRate / 100 / 12))
        : investment * Math.pow(1 + returnRate / 100, timePeriod);

    const estReturns = totalValue - investedAmount;

    const chartOptions = {
        chart: {
            type: "pie",
            backgroundColor: "#F3F9FF",
            options3d: {
                enabled: true,
                alpha: 45,
            },
        },
        title: {
            text: "Investment Breakdown",
            align: "center",
            style: { color: "#005555", fontSize: "16px" }
        },
        plotOptions: {
            pie: {
                innerSize: "60%",
                depth: 45,
                dataLabels: {
                    enabled: false,
                },
            },
        },
        legend: {
            enabled: true,
            layout: "horizontal",
            align: "left",
            verticalAlign: "bottom",
            itemStyle: {
                fontSize: "12px"
            }
        },
        series: [
            {
                name: "Amount",
                data: [
                    { name: "Invested Amount", y: investedAmount, color: "#0175FF" },
                    { name: "Est. Returns", y: estReturns, color: "#F57328" },
                ],
                showInLegend: true,
            },
        ],
    };

    return (
        <div className="section-properties container pb-12">
            {/* Top Navigation Section */}
            <div className="w-full flex justify-between items-center">
                <p
                    className="font-bold text-primary text-xl cursor-pointer my-4"
                    onClick={() => navigate(-1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} /> SIP Calculator
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

                <div className="md:col-span-2 shadow-sm p-6 bg-[#F3F9FF] rounded-lg border border-gray-300 grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="md:col-span-2">
                        <div className="flex space-x-4 mb-4">
                            <button onClick={() => setMode("SIP")} className={`px-4 py-2 rounded ${mode === "SIP" ? "bg-[#005555] text-white" : "bg-gray-300 text-gray-900"}`}>SIP</button>
                            <button onClick={() => setMode("Lumpsum")} className={`px-4 py-2 rounded ${mode === "Lumpsum" ? "bg-[#005555] text-white" : "bg-gray-300 text-gray-900"}`}>Lumpsum</button>
                        </div>
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">{mode === "SIP" ? "Monthly investment" : "Lumpsum investment"}</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">₹</div>
                                <input type="number" className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6" value={investment} onChange={(e) => setInvestment(Number(e.target.value))} />
                            </div>
                        </div>
                        <input type="range" className="w-full mb-4" min="1000" max="100000" value={investment} onChange={(e) => setInvestment(Number(e.target.value))} />
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Expected return rate (p.a)</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">%</div>
                                <input type="number" className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6" value={returnRate} onChange={(e) => setReturnRate(Number(e.target.value))} />
                            </div>
                        </div>
                        <input type="range" className="w-full mb-4" min="1" max="30" value={returnRate} onChange={(e) => setReturnRate(Number(e.target.value))} />
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Time period</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">Yr</div>
                                <input type="number" className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6" value={timePeriod} onChange={(e) => setTimePeriod(Number(e.target.value))} />
                            </div>
                        </div>
                        <input type="range" className="w-full mb-4" min="1" max="50" value={timePeriod} onChange={(e) => setTimePeriod(Number(e.target.value))} />

                        <div className="bg-[#DCEFF9] p-4 rounded-md text-gray-800" >
                            <p className="flex justify-between text-sm"><span>Invested amount</span> <span className="text-md font-semibold">₹{investedAmount.toLocaleString()}</span></p>
                            <p className="flex justify-between text-sm"><span>Est. returns</span> <span className="text-md font-semibold">₹{estReturns.toLocaleString()}</span></p>
                            <p className="flex justify-between text-sm mb-0"><span>Total value</span> <span className="text-md font-semibold">₹{totalValue.toLocaleString()}</span></p>
                        </div>
                    </div>
                    <div>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        <a
                            href="/"
                        >
                            <button className="w-full bg-[#005555] text-white py-2 rounded mt-4">
                                INVEST IN SHAREBRIC
                            </button>
                        </a>
                    </div>
                </div>

                <div className="bg-[#F3F9FF] border border-gray-300 rounded-lg">
                    <div className="text-gray-700 text-base font-semibold p-3">Popular Calculators</div>
                    <div className="flex flex-col divide-y divide-gray-300">
                        {[
                             { name: "SIP Calculator", link: "/sip-calculator" },
                             { name: "PPF Calculator", link: "/ppf-calculator" },
                             { name: "EPF Calculator", link: "/epf-calculator" },
                             { name: "Lumpsum Calculator", link: "/lumpsum-calculator" },
                             { name: "FD Calculator", link: "/fd-calculator" },
                             { name: "SWP Calculator", link: "/swp-calculator" },
                             { name: "Mutual Fund Returns Calculator", link: "/mutual-fund-calculator" },
                             { name: "RD Calculator", link: "/rd-calculator" },
                             { name: "SSY Calculator", link: "/ssy-calculator" },
                             { name: "Home Loan Calculator", link: "/home-loan-calculator" },
                        ].map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                className="p-3 text-gray-600 text-sm hover:text-[#fff] hover:bg-[#005555]"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                </div>

                <div className="md:col-span-2">
                    <h2 className="text-lg font-semibold mb-2">SIP Calculator – Systematic Investment Plan Calculator</h2>
                    <p className="text-gray-700">
                        Prospective investors can think that SIPs and mutual funds are the same. However, SIPs are merely a method of investing in mutual funds, the other method being a lump sum. A SIP calculator is a tool that helps you determine the returns you can avail when parking your funds in such investment tools. Systematic Investment Plan or SIP is a process of investing a fixed sum of money in mutual funds at regular intervals. SIPs usually allow you to invest weekly, quarterly, or monthly.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">What is a SIP Calculator?</h2>
                    <p className="text-gray-700">
                        A SIP calculator is a simple tool that allows individuals to get an idea of the returns on their mutual fund investments made through SIP. SIP investments in mutual funds have become one of the most popular investment options for millennials lately.
                    </p>
                    <p className="text-gray-700">
                        These mutual fund sip calculators are designed to give potential investors an estimate on their mutual fund investments. However, the actual returns offered by a mutual fund scheme varies depending on various factors. The SIP calculator does not provide clarification for the exit load and expense ratio (if any).
                    </p>
                    <p className="text-gray-700">
                        This calculator will calculate the wealth gain and expected returns for your monthly SIP investment. Indeed, you get a rough estimate on the maturity amount for any of your monthly SIP, based on a projected annual return rate.
                    </p>
                </div>

            </div>
        </div>
    );
};

export default SIPCalculator;
