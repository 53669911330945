import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import ContactUsPage from "./pages/contact_us";
import AboutPage from "./pages/about";
import ServicesPage from "./pages/services";
import FAQPage from "./pages/faq";
import RegisterPage from "./pages/register";
import LoginPage from "./pages/login";
import ForgotPage from "./pages/forgot-pass";
import HomePage from "./pages/home";
import AccountValidate from "./pages/account-validate";
import ResetPassPage from "./pages/reset-pass";
import PropertyPage from "./pages/property";
import AuthLayout from "./components/AuthLayout";
import PropertyDetailsPage from "./pages/propertyDetails";
import ProfilePage from "./pages/profile";
import PurchasedPage from "./pages/purchased";
import TransactionPage from "./pages/purchased/transaction";
import HowItWorksPage from "./pages/howItWorks";
import TermsAndConditionsPage from "./pages/termsAndconditions";
import NomineePage from "./pages/nominee";
import AccountDetails from "./pages/account-details";
import WithdrawPage from "./pages/withdraw/withdraw";
import BlogsPage from "./pages/blogs";
import Blog1 from "./pages/blogs/blog1";
import Blog2 from "./pages/blogs/blog2";
import Blog3 from "./pages/blogs/blog3";
import Blog4 from "./pages/blogs/blog4";
import Blog5 from "./pages/blogs/blog5";
import CalculatorsList from "./pages/calculators";
import SipCalculator from "./pages/calculators/sip";
import PPFCalculator from "./pages/calculators/ppf";
import EPFCalculator from "./pages/calculators/epf";
import MFCalculator from "./pages/calculators/mf";
import FDCalculator from "./pages/calculators/fd";
import LUMPSUMCalculator from "./pages/calculators/lumpsum";
import SWPCalculator from "./pages/calculators/swp";
import RDCalculator from "./pages/calculators/rd";
import SSYCalculator from "./pages/calculators/ssy";
import HomeLoanEMICalculator from "./pages/calculators/homeLoanEmi"
import NotFoundPage from "./pages/notFoundPage";

const IsAuthenticated = ({ children }) => {
  // const user = localStorage.getItem("token");
  // if (!user) {
  //   return <Navigate to={"/login"} />;
  // }
  return children;
};

const IsNotAuthenticated = ({ children }) => {
  // const user = localStorage.getItem("token");
  // if (user) {
  //   return <Navigate to={"/home"} />;
  // }
  return children;
};
function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/register" element={<IsNotAuthenticated><RegisterPage /></IsNotAuthenticated>} />
        <Route path="/reset-pass" element={<IsNotAuthenticated><ResetPassPage /></IsNotAuthenticated>} />
        <Route path="/account-validate" element={<IsNotAuthenticated><AccountValidate /></IsNotAuthenticated>} />
        <Route path="/forgot-password" element={<IsNotAuthenticated><ForgotPage /></IsNotAuthenticated>} />
        <Route path="/login" element={<IsNotAuthenticated><LoginPage /></IsNotAuthenticated>} />

        {/* Private Routes */}
        <Route path="/" element={<IsAuthenticated><AuthLayout pageName="Home"><HomePage /></AuthLayout></IsAuthenticated>} />
        {/* <Route path="/home" element={<IsAuthenticated><AuthLayout pageName="Home"><HomePage /></AuthLayout></IsAuthenticated>} /> */}
        <Route path="/contact-us" element={<IsAuthenticated><AuthLayout pageName="Contact Us"><ContactUsPage /></AuthLayout></IsAuthenticated>} />
        <Route path="/properties" element={<IsAuthenticated><AuthLayout pageName="Properties"><PropertyPage /></AuthLayout></IsAuthenticated>} />
        <Route path="/how-it-works" element={<IsAuthenticated><AuthLayout pageName="How It Works"><HowItWorksPage /></AuthLayout></IsAuthenticated>} />
        <Route path="/terms-and-conditions" element={<IsAuthenticated><AuthLayout pageName="Terms and Conditions"><TermsAndConditionsPage /></AuthLayout></IsAuthenticated>} />
        <Route path="/profile" element={<IsAuthenticated><AuthLayout pageName="Profile"><ProfilePage /></AuthLayout></IsAuthenticated>} />
        <Route path="/nominees-list" element={<IsAuthenticated><AuthLayout pageName="Nominee List"><NomineePage /></AuthLayout></IsAuthenticated>} />
        <Route path="/account-details" element={<IsAuthenticated><AuthLayout pageName="Account Details"><AccountDetails /></AuthLayout></IsAuthenticated>} />

        {/* Property Transactions */}
        <Route path="/purchased-properties" element={<IsAuthenticated><AuthLayout pageName="Property Details"><PurchasedPage /></AuthLayout></IsAuthenticated>} />
        <Route path="/withdraw-properties" element={<IsAuthenticated><AuthLayout pageName="Withdraw Properties"><WithdrawPage /></AuthLayout></IsAuthenticated>} />
        <Route path="/purchased-transaction" element={<IsAuthenticated><AuthLayout pageName="Property Transaction"><TransactionPage /></AuthLayout></IsAuthenticated>} />
        <Route path="/property-details" element={<IsAuthenticated><AuthLayout pageName="Property Details"><PropertyDetailsPage /></AuthLayout></IsAuthenticated>} />

        {/* Blogs */}
        {/* <Route path="/blogs"> */}
          <Route path="/blogs" element={<AuthLayout pageName="Blogs"><BlogsPage /></AuthLayout>} />
          <Route path="/blog1" element={<AuthLayout pageName="Blog"><Blog1 /></AuthLayout>} />
          <Route path="/blog2" element={<AuthLayout pageName="Blog"><Blog2 /></AuthLayout>} />
          <Route path="/blog3" element={<AuthLayout pageName="Blog"><Blog3 /></AuthLayout>} />
          <Route path="/blog4" element={<AuthLayout pageName="Blog"><Blog4 /></AuthLayout>} />
          <Route path="/blog5" element={<AuthLayout pageName="Blog"><Blog5 /></AuthLayout>} />
        {/* </Route> */}

        {/* Additional Pages */}
        <Route path="/about" element={<AuthLayout pageName="About"><AboutPage /></AuthLayout>} />
        <Route path="/services" element={<AuthLayout pageName="Services"><ServicesPage /></AuthLayout>} />
        <Route path="/faq" element={<AuthLayout pageName="FAQ"><FAQPage /></AuthLayout>} />

        {/* Calculator Routes */}
        {/* <Route path="/calculator"> */}
          <Route path="/calculators" element={<AuthLayout pageName="Calculator"><CalculatorsList /></AuthLayout>} />
          <Route path="/sip-calculator" element={<AuthLayout pageName="SIP Calculator"><SipCalculator /></AuthLayout>} />
          <Route path="/ppf-calculator" element={<AuthLayout pageName="PPF Calculator"><PPFCalculator /></AuthLayout>} />
          <Route path="/epf-calculator" element={<AuthLayout pageName="EPF Calculator"><EPFCalculator /></AuthLayout>} />
          <Route path="/mutual-fund-calculator" element={<AuthLayout pageName="MF Calculator"><MFCalculator /></AuthLayout>} />
          <Route path="/fd-calculator" element={<AuthLayout pageName="FD Calculator"><FDCalculator /></AuthLayout>} />
          <Route path="/swp-calculator" element={<AuthLayout pageName="SWP Calculator"><SWPCalculator /></AuthLayout>} />
          <Route path="/lumpsum-calculator" element={<AuthLayout pageName="LUMPSUM Calculator"><LUMPSUMCalculator /></AuthLayout>} />
          <Route path="/rd-calculator" element={<AuthLayout pageName="RD Calculator"><RDCalculator /></AuthLayout>} />
          <Route path="/ssy-calculator" element={<AuthLayout pageName="SSY Calculator"><SSYCalculator /></AuthLayout>} />
          <Route path="/home-loan-calculator" element={<AuthLayout pageName="EMI Home Loan Calculator"><HomeLoanEMICalculator /></AuthLayout>} />
        {/* </Route> */}

        {/* 404 Page */}
        <Route path="*" element={<AuthLayout><NotFoundPage /></AuthLayout>} />
      </Routes>
    </Router>

  );
}

export default App;
