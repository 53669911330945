import React, { useState } from "react";

const blogs = [
  {
    id: 1,
    title: "Understanding Fractional Ownership in India: A Growing Trend in Real Estate Investment",
    date: "20 Sep 24",
    author: "Admin",
    description: "Fractional ownership is rapidly emerging as a transformative concept in India’s real estate market.",
    image: "images/hero_bg_2.jpg",
    link: "/blog4"
  },
  {
    id: 2,
    title: "Unlocking the Power of Real Estate Investment in India: A Path to Financial Freedom",
    date: "26 Sep 24",
    author: "Admin",
    description: "India’s real estate market is multifaceted, dynamic, and fast-evolving.",
    image: "images/img_6.jpg",
    link: "/blog5"
  },
  {
    id: 3,
    title: "Passive Income from Real Estate Investment in India: A Comprehensive Guide",
    date: "26 Sep 24",
    author: "Admin",
    description: "In today’s fast-paced world, achieving financial independence is a goal many aspire to Real estate.",
    image: "images/img_3.jpg",
    link: "/blog3"
  },
  {
    id: 4,
    title: "Traditional Investment in Real Estate in India: A Comprehensive Guide",
    date: "26 Sep 24",
    author: "Admin",
    description: "Real estate in India has been a tried-and-tested investment vehicle for decades...",
    image: " https://v3.material-tailwind.com/house-3.png",
    link: "/blog2"
  },
  {
    id: 5,
    title: "Traditional Real Estate Ownership vs. Fractional Ownership: A Detailed Comparison",
    date: "26 Sep 24",
    author: "Admin",
    description: "Real estate has long been regarded as one of the most reliable and profitable forms of investment.",
    image: "images/img_1.jpg",
    link: "/blog1"
  },
];

const BlogList = ({ setSelectedBlog }) => {


  return (
    <section className="pb-16">
      <p className="text-3xl text-primary font-bold text-center w-full mb-4">Our Blogs</p>
      <div className="container mx-auto grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {blogs.map((blog) => (
          <div
            key={blog.id}
            className="w-full rounded-lg border shadow-sm overflow-hidden bg-background border-surface shadow-black/5 hover:shadow-lg transition-transform duration-300 hover:bg-[#F8F9FA] animate-fadeIn flex flex-col"
          >
            {/* Image */}
            <div className="h-60 m-0 p-4 w-full rounded-none">
              <img
                src={blog.image}
                alt="Invest with Share Bric"
                className="h-full w-full object-cover object-center rounded shadow-lg"
              />
            </div>

            {/* Content (Grows to push button down) */}
            <div className="w-full h-max rounded py-2.5 px-6 pb-0 pt-2 flex-grow">
              <small className="font-sans antialiased text-sm text-primary mb-2 block font-semibold">
                {blog.date} | {blog.author}
              </small>
              <h6 className="font-sans antialiased font-bold text-base md:text-lg lg:text-xl text-black dark:text-white mb-2">
                {blog.title}
              </h6>
              <p className="font-sans antialiased text-base text-foreground">{blog.description}</p>
            </div>

            {/* Button at Bottom */}
            <div className="w-full rounded p-6 mt-auto">
            {/* <p><a href="#" className="learn-more">Read more</a></p> */}
              <a href={blog.link}>
                <button
                  className="overflow-hidden inline-flex items-center justify-center border align-middle select-none font-sans font-medium text-center transition-all duration-300 ease-in disabled:opacity-50 disabled:shadow-none disabled:cursor-not-allowed text-sm rounded-md py-1.5 px-3 shadow-sm hover:shadow bg-[#005555] border-primary text-white hover:bg-[#008080] hover:border-[#008080] focus:shadow-none"
                >
                  Read More
                </button>
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>

  );
};

const BlogSection = () => {
  const [selectedBlog, setSelectedBlog] = useState(null);
  return <BlogList setSelectedBlog={setSelectedBlog} />;
};

export default BlogSection;
