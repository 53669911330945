import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faHome } from "@fortawesome/free-solid-svg-icons";
import { signupApi } from "../../api";
import toast from "toastr";
import Loader from "../../components/Loader";

const RegisterPage = () => {
  toast.options = { preventDuplicates: true };
  const navigate = useNavigate();
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    referralCode: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordToggle = () => setShowPassword(!showPassword);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setErrors({});
  };

  const validate = () => {
    const newErrors = {};
    if (!data?.firstName.trim()) {
      newErrors.firstName = "First name is required";
    } else if (!data?.lastName.trim()) {
      newErrors.lastName = "Last name is required";
    } else if (!data?.mobileNumber.trim()) {
      newErrors.mobileNumber = "Mobile number is required";
    } else if (!/^\d{10}$/.test(data?.mobileNumber)) {
      newErrors.mobileNumber = "Please enter a valid Mobile number";
    }
    if (!data?.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data?.email)) {
      newErrors.email = "Please enter a valid email address";
    } else if (!data.password.trim()) {
      newErrors.password = "Password is required";
    } else if (data.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters";
    } else if (!/[A-Z]/.test(data.password)) {
      newErrors.password = "Password must contain at least one capital letter";
    } else if (!/[0-9]/.test(data.password)) {
      newErrors.password = "Password must contain at least one digit";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(data.password)) {
      newErrors.password =
        "Password must contain at least one special character";
    } else if (/\s/.test(data.password)) {
      newErrors.password = "Password must not contain spaces";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validate()) {
        setLoader(true);
        const obj = {
          email: data?.email,
          password: data?.password,
          mobile: data?.mobileNumber,
          firstName: data?.firstName,
          lastName: data?.lastName,
          referralCode: data?.referralCode,
          countryCode: "+91",
        };
        const res = await signupApi(obj);
        console.log(res, "res");

        if (res?.status === 200) {
          setLoader(false);
          localStorage.setItem("token", res?.data?.data?.accessToken);
          localStorage.setItem("userInfo", JSON.stringify(res?.data?.data));
          toast.success(res?.data?.message);
          navigate("/");
        } else if (res?.data?.error_description?.statusCode === 400) {
          toast.error(res?.data?.error_description?.message);
          setLoader(false);
          navigate(-1);
        } else {
          toast.error(res?.data?.error_description?.message);
          setLoader(false);
        }
      }
    } catch (err) {
      console.log(err, "err");
      setLoader(false);

      if (err?.response?.status === 404) {
        navigate(-1);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="bg-[#005555] w-full h-screen flex flex-col justify-center py-8 align-center items-center">
        <div className="w-[350px] md:w-[400px] lg:w-[650px] bg-white rounded-lg shadow py-2 md:py-6">
          <>
            <h2 className="text-2xl text-black text-center w-full font-bold mt-4">
              Register
            </h2>
            <form onSubmit={handleSubmit} className="w-full h-full px-8 pt-8">
              <div className="flex flex-row gap-4 mb-4">
                <div className="w-1/2">
                  <label className="text-black text-sm mb-2 block">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={data.firstName}
                    onChange={handleChange}
                    className="w-full px-2 py-2 bg-gray-200 rounded-md"
                    placeholder="First Name"
                  />
                  {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
                </div>
                <div className="w-1/2">
                  <label className="text-black text-sm mb-2 block">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={data.lastName}
                    onChange={handleChange}
                    className="w-full px-2 py-2 bg-gray-200 rounded-md"
                    placeholder="Last Name"
                  />
                  {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
                </div>
              </div>

              <div className="flex flex-row gap-4 mb-4">
                <div className="w-1/2">
                  <label className="text-black text-sm mb-2 block">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    className="w-full px-2 py-2 bg-gray-200 rounded-md"
                    placeholder="Email"
                  />
                  {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                </div>
                <div className="w-1/2 relative">
                  <label className="text-black text-sm mb-2 block">Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={data.password}
                    onChange={handleChange}
                    className="w-full text-sm px-2 py-2 bg-gray-200 rounded-md"
                    placeholder="Password"
                  />
                  <span
                    className="absolute right-3 top-9 cursor-pointer"
                    onClick={handlePasswordToggle}
                  >
                    <FontAwesomeIcon
                      className="text-black"
                      icon={showPassword ? faEyeSlash : faEye}
                    />
                  </span>
                  {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
                </div>

              </div>

              <div className="flex flex-row gap-4 mb-4">
                <div className="w-1/2">
                  <label className="text-black text-sm mb-2 block">Mobile Number</label>
                  <input
                    type="text"
                    name="mobileNumber"
                    value={data.mobileNumber}
                    onChange={handleChange}
                    className="w-full px-2 py-2 bg-gray-200 rounded-md"
                    placeholder="Mobile Number"
                  />
                  {errors.mobileNumber && <p className="text-red-500 text-xs mt-1">{errors.mobileNumber}</p>}
                </div>
                <div className="w-1/2">
                  <label className="text-black text-sm mb-2 block">Referral Code</label>
                  <input
                    type="text"
                    name="referralCode"
                    value={data.referralCode}
                    onChange={handleChange}
                    className="w-full px-2 py-2 bg-gray-200 rounded-md"
                    placeholder="Referral Code"
                  />
                </div>
              </div>

              <div className="w-full flex item-center justify-center mt-8">
                <button
                  type="submit"
                  className="cursor-pointer bg-[#005555] px-2 py-2 text-white rounded-md w-full"
                >
                  Register
                </button>
              </div>
              <div className="w-full flex item-center justify-center mt-2">
                <button
                  type="button"
                  className="cursor-pointer text-[#005555] px-2 py-2 rounded-md w-full"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  <u>Already have an account!</u>
                </button>
              </div>
              {/* <div className="w-full">
                <p className="w-full text-center text-black text-sm my-4">
                  <u
                    className="cursor-pointer"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    GO to Home
                  </u>
                </p>
              </div> */}
              <div className="w-full mt-3 mb-3 flex items-center justify-center">
                <button
                  className="flex items-center gap-2 px-4 py-2 bg-[#005555] text-white rounded-full shadow-md hover:bg-[#007777] transition-all duration-300"
                  onClick={() => navigate("/")}
                >
                  <FontAwesomeIcon icon={faHome} className="text-lg" />
                  <span className="text-sm font-medium">Go to Home</span>
                </button>
              </div>
            </form>
          </>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
