const AboutPage = () => {
  return (
    <>
      <div>
        <p className="text-3xl  text-primary font-bold text-center w-full mb-4">
          About Us
        </p>
        <div className="section -mt-12">
          <div className="container">
            <div className="row text-left mb-5">
              <div className="col-12">
                <h2 className="font-bold text-primary mb-4 slow-appear-text">
                  Welcome to ShareBric
                </h2>
              </div>
              <p className="text-black-50 slow-appear-text">
                <i>
                  {" "}
                  Your Gateway to Affordable and Transparent Residential Real
                  Estate Investment!
                </i>
              </p>
              <p className="text-black-50 slow-appear-text">
                At ShareBrick we specialize in connecting investors with
                affordable residential properties that offer excellent value and
                profit potential. Our platform simplifies the investment
                process, providing access to carefully vetted listings,
                educational resources, and expert support
              </p>
              <span className="text-xl  text-primary  font-bold slow-appear-text">
                Our Mission
              </span>
              <p className="text-black-50 slow-appear-text">
                At ShareBrick, our mission is simple: to democratize real estate
                investment and make it accessible to everyone. We believe that
                everyone deserves the opportunity to build wealth and have more
                than one income stream, regardless of their financial status or
                experience level- Real Estate has the potential to provide us
                all that opportunity. Through our platform, we aim to provide
                investors with the tools, resources, and support they need to
                succeed in the dynamic world of residential real estate
                investment!
              </p>
              <span className="text-xl  text-primary  font-bold slow-appear-text">
                Who are we?
              </span>
              <p className="text-black-50 slow-appear-text">
                ShareBrick is your trusted destination for residential real
                estate investment opportunities. Whether you are dipping your
                toes into the world of real estate or a seasoned investor, we've
                curated a selection of properties offering excellent value and
                long-term profit potential. Our platform ensures affordability
                without compromising on quality. Each property is rigorously
                vetted by local experts before being added to our growing
                portfolio- so you can invest with confidence. From starter homes
                to lavish bungalows, we've got options to fit every pocket.
                Access our educational resources, market insights, and
                investment tools tailored to help you maximize returns without
                overspending. Join our community of investors and let us be a
                part of your journey towards financial freedom through
                residential real estate. With ShareBrick, residential investment
                is now within reach for everyone
              </p>
            </div>
          </div>
        </div>

        <div className="section pt-0 pb-8 slow-appear-text">
          <div className="container">
            <div className="row justify-content-between mb-5">
              <div className="col-lg-7 mb-5 mb-lg-0 order-lg-2">
                <div className="img-about">
                  <img
                    src="images/hero_bg_3.jpg"
                    alt="Image"
                    className="img-fluid mt-6"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <h3 className="font-bold ">How It Works</h3>
                <div className="d-flex feature-h">
                  <div className="feature-text">
                    <h3 className="heading">Property Selection</h3>
                    <p className="text-black-50 text-xs">
                      We curate a diverse selection of residential properties,
                      from starter homes to multifamily units, ensuring options
                      for investors of all budgets and preferences.
                    </p>
                  </div>
                </div>

                <div className="d-flex feature-h">
                  <div className="feature-text">
                    <h3 className="heading">Vetting</h3>
                    <p className="text-black-50  text-xs">
                      Each listing undergoes a rigorous vetting process to
                      ensure quality and profit potential.
                    </p>
                  </div>
                </div>

                <div className="d-flex feature-h">
                  <div className="feature-text">
                    <h3 className="heading">Investor Access</h3>
                    <p className="text-black-50  text-xs">
                      Investors browse through listings, accessing detailed
                      information, financial projections, and investment
                      analysis to make informed decisions.
                    </p>
                  </div>
                </div>
                <div className="d-flex feature-h">
                  <div className="feature-text">
                    <h3 className="heading">Educational Resources</h3>
                    <p className="text-black-50 text-xs">
                      We provide investors with access to guides, market
                      insights, and investment tools to enhance their knowledge
                      and decision-making process.
                    </p>
                  </div>
                </div>
                <div className="d-flex feature-h">
                  <div className="feature-text">
                    <h3 className="heading">Support and Guidance </h3>
                    <p className="text-black-50">
                      Throughout the investment process, our team offers expert
                      support and guidance, assisting with due diligence,
                      negotiations, and portfolio management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-between mb-5">
            <div className="col-lg-7 mb-5 mb-lg-0 ">
              <div className="img-about">
                <img src="images/hero_bg_2.jpg" alt="Image" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-4">
              <h3 className="font-bold">Why Us?</h3>
              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading">Affordability</h3>
                  <p className="text-black-50 text-xs">
                    We specialize in providing affordable residential real
                    estate investment opportunities, allowing investors of all
                    budgets and expertise to participate and build wealth
                    without breaking the bank.
                  </p>
                </div>
              </div>

              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading">Quality Assurance</h3>
                  <p className="text-black-50  text-xs">
                    We meticulously vet each property listed on our platform to
                    ensure that they meet our standards for quality and profit
                    potential, giving investors the confidence of knowing
                    they're investing in properties with great returns on
                    investment.
                  </p>
                </div>
              </div>

              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading">Diverse Selection</h3>
                  <p className="text-black-50  text-xs">
                    In addition to our listings, we provide investors with
                    access to educational resources, market insights, and
                    investment tools designed to empower them with the knowledge
                    and support needed to make informed decisions and succeed in
                    the real estate market
                  </p>
                </div>
              </div>

              <div className="d-flex feature-h">
                <div className="feature-text">
                  <h3 className="heading">Comprehensive Support</h3>
                  <p className="text-black-50  text-xs">
                    In addition to our listings, we provide investors with
                    access to educational resources, market insights, and
                    investment tools designed to empower them with the knowledge
                    and support needed to make informed decisions and succeed in
                    the real estate market
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container slow-appear-text">
          <div className="row text-left mb-5">
            <span className="text-xl font-bold  text-primary  mb-4">
              {" "}
              Start Investing Today!
            </span>

            <p className="text-black-50 mb-12 slow-appear-text">
              Join our community of investors and start your journey towards
              financial freedom through affordable residential real estate
              investment. Explore listings, access resources, and get expert
              support at ShareBrick.
            </p>

            <p className="text-black-50 slow-appear-text">
              At ShareBrick, we maximize returns for investors through
              data-driven decision-making. By analyzing market trends, property
              performance metrics, and financial projections, we identify
              opportunities with the highest potential for profit. Our platform
              provides investors with access to comprehensive data and
              analytics, empowering them to make informed investment decisions.
              With our data-driven approach, investors can confidently navigate
              the real estate market and achieve their financial goals with
              precision and efficiency.
            </p>
          </div>
        </div>
        {/* <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <img src="images/img_1.jpg" alt="Image" className="img-fluid" />
              </div>
              <div className="col-md-4 mt-lg-5">
                <img src="images/img_3.jpg" alt="Image" className="img-fluid" />
              </div>
              <div className="col-md-4">
                <img src="images/img_2.jpg" alt="Image" className="img-fluid" />
              </div>
            </div>
            <div className="row section-counter mt-5">
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <div className="counter-wrap mb-5 mb-lg-0">
                  <span className="number">
                    <span className="countup text-primary">2917</span>
                  </span>
                  <span className="caption text-black-50"># of Buy Properties</span>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <div className="counter-wrap mb-5 mb-lg-0">
                  <span className="number">
                    <span className="countup text-primary">3918</span>
                  </span>
                  <span className="caption text-black-50">
                    # of Sell Properties
                  </span>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <div className="counter-wrap mb-5 mb-lg-0">
                  <span className="number">
                    <span className="countup text-primary">38928</span>
                  </span>
                  <span className="caption text-black-50"># of All Properties</span>
                </div>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <div className="counter-wrap mb-5 mb-lg-0">
                  <span className="number">
                    <span className="countup text-primary">1291</span>
                  </span>
                  <span className="caption text-black-50"># of Agents</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section sec-testimonials bg-light">
          <div className="container">
            <div className="row mb-5 align-items-center">
              <div className="col-md-6">
                <h2 className="font-weight-bold heading text-primary mb-4 mb-md-0">
                  The Team
                </h2>
              </div>
              <div className="col-md-6 text-md-end">
                <div id="testimonial-nav">
                  <span className="prev" data-controls="prev">
                    Prev
                  </span>

                  <span className="next" data-controls="next">
                    Next
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4"></div>
            </div>
            <div className="testimonial-slider-wrap">
              <div className="testimonial-slider">
                <div className="item">
                  <div className="testimonial">
                    <img
                      src="images/person_1-min.jpg"
                      alt="Image"
                      className="img-fluid rounded-circle w-25 mb-4"
                    />
                    <h3 className="h5 text-primary">James Smith</h3>
                    <p className="text-black-50">Designer, Co-founder</p>

                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separated they live in Bookmarksgrove right at the
                      coast of the Semantics, a large language ocean.
                    </p>

                    <ul className="social list-unstyled list-inline dark-hover">
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-linkedin"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="item">
                  <div className="testimonial">
                    <img
                      src="images/person_2-min.jpg"
                      alt="Image"
                      className="img-fluid rounded-circle w-25 mb-4"
                    />
                    <h3 className="h5 text-primary">Carol Houston</h3>
                    <p className="text-black-50">Designer, Co-founder</p>

                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separated they live in Bookmarksgrove right at the
                      coast of the Semantics, a large language ocean.
                    </p>

                    <ul className="social list-unstyled list-inline dark-hover">
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-linkedin"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="item">
                  <div className="testimonial">
                    <img
                      src="images/person_3-min.jpg"
                      alt="Image"
                      className="img-fluid rounded-circle w-25 mb-4"
                    />
                    <h3 className="h5 text-primary">Synthia Cameron</h3>
                    <p className="text-black-50">Designer, Co-founder</p>

                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separated they live in Bookmarksgrove right at the
                      coast of the Semantics, a large language ocean.
                    </p>

                    <ul className="social list-unstyled list-inline dark-hover">
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-linkedin"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="item">
                  <div className="testimonial">
                    
                    <h3 className="h5 text-primary">Davin Smith</h3>
                    <p className="text-black-50">Designer, Co-founder</p>

                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separated they live in Bookmarksgrove right at the
                      coast of the Semantics, a large language ocean.
                    </p>

                    <ul className="social list-unstyled list-inline dark-hover">
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-twitter"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-linkedin"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#">
                          <span className="icon-instagram"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default AboutPage;
