import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";

const EPFCalculator = () => {
    const navigate = useNavigate();
    const [salary, setSalary] = useState(50000);
    const [age, setAge] = useState(30);
    const [contribution, setContribution] = useState(12);
    const [annualIncrease, setAnnualIncrease] = useState(5);
    const [interestRate, setInterestRate] = useState(8.1);

    const retirementAge = 60;
    const yearsLeft = retirementAge - age;

    let balance = 0;
    let yearlySalary = salary * 12;

    for (let i = 0; i < yearsLeft; i++) {
        let yearlyContribution = (yearlySalary * contribution) / 100;
        balance = (balance + yearlyContribution) * (1 + interestRate / 100);
        yearlySalary *= 1 + annualIncrease / 100;
    }

    return (
        <div className="section-properties container pb-12">
            {/* Top Navigation Section */}
            <div className="w-full flex justify-between items-center">
                <p
                    className="font-bold text-primary text-xl cursor-pointer my-4"
                    onClick={() => navigate(-1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} /> EPF Calculator
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

                <div className="md:col-span-2 p-6 bg-[#F3F9FF] shadow-sm rounded-lg border border-gray-300 shadow-sm grid grid-cols-1">
                    <div className="my-3 flex items-center justify-between space-x-4">
                        <label className="text-gray-700">Monthly Salary (Basic + DA)</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">₹</div>
                            <input type="number" className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6" value={salary} onChange={(e) => setSalary(Number(e.target.value))} />
                        </div>
                    </div>
                    <input type="range" className="w-full mb-4" min="10000" max="200000" value={salary} onChange={(e) => setSalary(Number(e.target.value))} />

                    <div className="my-3 flex items-center justify-between space-x-4">
                        <label className="text-gray-700">Your Age</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">Yr</div>
                            <input type="number" className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6" value={age} onChange={(e) => setAge(Number(e.target.value))} />
                        </div>
                    </div>
                    <input type="range" className="w-full mb-4" min="18" max="60" value={age} onChange={(e) => setAge(Number(e.target.value))} />

                    <div className="my-3 flex items-center justify-between space-x-4">
                        <label className="text-gray-700">Your Contribution to EPF</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">%</div>
                            <input type="number" className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6" value={contribution} onChange={(e) => setContribution(Number(e.target.value))} />
                        </div>
                    </div>
                    <input type="range" className="w-full mb-4" min="1" max="20" value={contribution} onChange={(e) => setContribution(Number(e.target.value))} />

                    <div className="my-3 flex items-center justify-between space-x-4">
                        <label className="text-gray-700">Annual Increase in Salary</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">%</div>
                            <input type="number" className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6" value={annualIncrease} onChange={(e) => setAnnualIncrease(Number(e.target.value))} />
                        </div>
                    </div>
                    <input type="range" className="w-full mb-4" min="0" max="15" value={annualIncrease} onChange={(e) => setAnnualIncrease(Number(e.target.value))} />

                    <div className="my-3 flex items-center justify-between space-x-4">
                        <label className="text-gray-700">Rate of Interest</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">%</div>
                            <input type="number" className="bg-[#DCEFF9] border border-gray-300 text-[#ff0000] text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full py-2 px-6" readOnly value={interestRate} onChange={(e) => setInterestRate(Number(e.target.value))} />
                        </div>
                    </div>
                    {/* <input type="range" className="w-full mb-4" min="5" max="12" value={interestRate} onChange={(e) => setInterestRate(Number(e.target.value))} />
                 */}
                    <div className="bg-[#DCEFF9] my-3 p-4 rounded-md text-gray-800 text-center">
                        <p className="text-md font-medium">You will have accumulated</p>
                        <p className="text-3xl font-bold">₹{balance.toLocaleString()}</p>
                        <p className="text-md font-medium mb-0">By the time you retire</p>
                    </div>
                </div>

                <div className="bg-[#F3F9FF] border border-gray-300 rounded-lg" style={{ height: 'fit-content'}}>
                    <div className="text-gray-700 text-base font-semibold p-3">Popular Calculators</div>
                    <div className="flex flex-col divide-y divide-gray-300">
                        {[
                             { name: "SIP Calculator", link: "/sip-calculator" },
                             { name: "PPF Calculator", link: "/ppf-calculator" },
                             { name: "EPF Calculator", link: "/epf-calculator" },
                             { name: "Lumpsum Calculator", link: "/lumpsum-calculator" },
                             { name: "FD Calculator", link: "/fd-calculator" },
                             { name: "SWP Calculator", link: "/swp-calculator" },
                             { name: "Mutual Fund Returns Calculator", link: "/mutual-fund-calculator" },
                             { name: "RD Calculator", link: "/rd-calculator" },
                             { name: "SSY Calculator", link: "/ssy-calculator" },
                             { name: "Home Loan Calculator", link: "/home-loan-calculator" },
                        ].map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                 className="p-3 text-gray-600 text-sm hover:text-[#fff] hover:bg-[#005555]"
                                // className="p-3 text-gray-600 text-sm hover:text-[#005555] hover:bg-[#DCEFF9]"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                </div>


                <div className="md:col-span-2">
                    <p className="text-gray-700">
                        Most private sector employees are entitled to receive post-retirement benefits if they function in the organised sector. Note that government employees are additionally eligible for pensions unlike their private sector counterparts. Employee Provident Fund was set up after the EPF Act was passed in the Parliament. Under the law, the Employees Provident Fund Organisation of India (or EFPO) controls the funds deposited by both the employee and employer in a permanent account, affixed by an UAN or Unique Account Number. An EPF calculator can help you estimate your savings appropriately.
                    </p>
                    <p className="text-gray-700">
                        The PF calculator uses proprietary technology to fetch the correct sum every time you input data. Provident Fund acts as a guarantee for future prosperity or loss of employment, and is of great use for future financial decision-making.
                    </p>
                    <h2 className="text-lg font-semibold mb-2">How can an EPF calculator help you?</h2>
                    <p className="text-gray-700">
                        Once you start using our PF calculator in India, you can easily track where your hard-earned money is being stored, and how much interest it has accumulated.
                    </p>


                </div>

            </div>
        </div>
    );
};

export default EPFCalculator;
