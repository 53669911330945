import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const FDCalculator = () => {
    const navigate = useNavigate();
    const [investment, setInvestment] = useState(100000);
    const [interestRate, setInterestRate] = useState(6.5);
    const [timePeriod, setTimePeriod] = useState(5);
    const [mode, setMode] = useState("Lumpsum");
    const [returnRate, setReturnRate] = useState(6);

    const interestRateDecimal = interestRate / 100;
    const maturityAmount = investment * Math.pow(1 + interestRateDecimal / 4, 4 * timePeriod);
    const estReturns = maturityAmount - investment;

    const totalValue = investment + estReturns;

    const chartOptions = {
        chart: {
            type: "pie",
            backgroundColor: "#F3F9FF",
            options3d: { enabled: true, alpha: 45 },
        },
        title: {
            text: "Investment Breakdown",
            align: "center",
            style: { color: "#005555", fontSize: "16px" },
        },
        plotOptions: {
            pie: {
                innerSize: "60%",
                depth: 45,
                dataLabels: { enabled: false },
            },
        },
        legend: {
            enabled: true,
            layout: "horizontal",
            align: "left",
            verticalAlign: "bottom",
            itemStyle: { fontSize: "12px" },
        },
        series: [
            {
                name: "Amount",
                data: [
                    { name: "Invested Amount", y: investment, color: "#0175FF" },
                    { name: "Est. Returns", y: estReturns, color: "#F57328" },
                ],
                showInLegend: true,
            },
        ],
    };

    return (
        <div className="section-properties container pb-12">
            <div className="w-full flex justify-between items-center">
                <p className="font-bold text-primary text-xl cursor-pointer my-4" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faArrowLeft} /> FD Calculator
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="md:col-span-2 shadow-sm p-6 bg-[#F3F9FF] rounded-lg border border-gray-300 grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="md:col-span-2">
                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">
                                {"Total Investment"}
                            </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-2 pointer-events-none">₹</div>
                                <input
                                    type="number"
                                    className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 px-6"
                                    value={investment}
                                    onChange={(e) => setInvestment(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <input
                            type="range"
                            className="w-full mb-4"
                            min="1000"
                            max="1000000"
                            value={investment}
                            onChange={(e) => setInvestment(Number(e.target.value))}
                        />

                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Expected Return Rate (p.a)</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">%</div>
                                <input
                                    type="number"
                                    className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 px-6"
                                    value={returnRate}
                                    onChange={(e) => setReturnRate(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <input
                            type="range"
                            className="w-full mb-4"
                            min="1"
                            max="30"
                            value={returnRate}
                            onChange={(e) => setReturnRate(Number(e.target.value))}
                        />

                        <div className="my-3 flex items-center justify-between space-x-4">
                            <label className="text-gray-700">Time Period</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">Yr</div>
                                <input
                                    type="number"
                                    className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 px-6"
                                    value={timePeriod}
                                    onChange={(e) => setTimePeriod(Number(e.target.value))}
                                />
                            </div>
                        </div>
                        <input
                            type="range"
                            className="w-full mb-4"
                            min="1"
                            max="50"
                            value={timePeriod}
                            onChange={(e) => setTimePeriod(Number(e.target.value))}
                        />
                        <br/>

                        <div className="bg-[#DCEFF9] p-4 rounded-md text-gray-800">
                            <p className="flex justify-between text-sm">
                                <span>Invested Amount</span>
                                <span className="text-md font-semibold">₹{investment.toLocaleString()}</span>
                            </p>
                            <p className="flex justify-between text-sm">
                                <span>Est. Returns</span>
                                <span className="text-md font-semibold">₹{estReturns.toLocaleString()}</span>
                            </p>
                            <p className="flex justify-between text-sm mb-0">
                                <span>Total Value</span>
                                <span className="text-md font-semibold">₹{totalValue.toLocaleString()}</span>
                            </p>
                        </div>
                    </div>

                    <div>
                        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                        <a href="/">
                            <button className="w-full bg-[#005555] text-white py-2 rounded mt-4">
                            INVEST IN SHAREBRIC
                            </button>
                        </a>
                    </div>
                </div>

                <div className="bg-[#F3F9FF] border border-gray-300 rounded-lg">
                    <div className="text-gray-700 text-base font-semibold p-3">Popular Calculators</div>
                    <div className="flex flex-col divide-y divide-gray-300">
                        {[
                             { name: "SIP Calculator", link: "/sip-calculator" },
                             { name: "PPF Calculator", link: "/ppf-calculator" },
                             { name: "EPF Calculator", link: "/epf-calculator" },
                             { name: "Lumpsum Calculator", link: "/lumpsum-calculator" },
                             { name: "FD Calculator", link: "/fd-calculator" },
                             { name: "SWP Calculator", link: "/swp-calculator" },
                             { name: "Mutual Fund Returns Calculator", link: "/mutual-fund-calculator" },
                             { name: "RD Calculator", link: "/rd-calculator" },
                             { name: "SSY Calculator", link: "/ssy-calculator" },
                             { name: "Home Loan Calculator", link: "/home-loan-calculator" },
                        ].map((item, index) => (
                            <a
                                key={index}
                                href={item.link}
                                 className="p-3 text-gray-600 text-sm hover:text-[#fff] hover:bg-[#005555]"
                            >
                                {item.name}
                            </a>
                        ))}
                    </div>
                </div>

                <div className="md:col-span-2">
                    <h2 className="text-lg font-semibold mb-2">Fixed Deposit Calculator – FD Maturity Calculator</h2>
                    <p className="text-gray-700">
                        A fixed deposit is a type of term investment offered by several banks and NBFCs. These deposits typically offer a higher rate of interest, subject to certain terms and conditions. The amount you deposit in these deposits is locked for a predetermined period, which can vary between 7 days and 10 years. Fixed deposits are one of the safest investment options as they are not exposed to market fluctuations.
                    </p>
                    <h3 className="text-lg font-semibold mb-2">How can an FD calculator help you?</h3>
                    <p className="text-gray-700">
                        Calculating the maturity amount of an FD can be a complicated and time-consuming process. An online FD calculator enables you to figure it out without breaking a sweat. FD maturity calculations involve multiple variables, such as the deposit amount, interest rate, tenure, and the type of interest (simple or compound). The calculator does all the hard work and gives you accurate figures with just a click of a button.
                    </p>
                    <p className="text-gray-700">
                        It helps you save a lot of time on these complex calculations. By inputting the principal amount, interest rate, and the investment tenure, you can quickly estimate the maturity amount of your FD. Additionally, the FD calculator can factor in the type of interest (monthly, quarterly, or yearly), helping you get an accurate maturity amount based on the interest compounding frequency.
                    </p>
                    <p className="text-gray-700">
                        An FD calculator also enables you to compare the maturity amount and interest rates of FDs offered by different financial institutions. This feature allows you to choose the best FD plan based on your financial goals. With various banks and NBFCs offering different interest rates, the calculator provides an easy way to compare and make an informed decision.
                    </p>
                    <h3 className="text-lg font-semibold mb-2">Why should you use an FD calculator?</h3>
                    <p className="text-gray-700">
                        Fixed deposits are popular due to their safety and predictable returns. However, manually calculating the returns on an FD can be tedious and prone to errors. The FD calculator simplifies this process, providing you with accurate results instantly. Whether you are planning for short-term savings or long-term investment, the calculator helps you assess how your money will grow over time and helps you plan better.
                    </p>
                    <p className="text-gray-700">
                        Using an FD calculator can also aid in tax planning. Since interest income from FDs is taxable, the calculator can help you estimate your post-tax returns. It allows you to choose different tax scenarios and see how the interest income impacts your overall return.
                    </p>
                </div>


            </div>
        </div>
    );
};

export default FDCalculator;
