import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPlus, faMinus, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// 1) Helper: Add months to a Date
function addMonths(date, monthsToAdd) {
  return new Date(date.getFullYear(), date.getMonth() + monthsToAdd, 1);
}

// 2) Helper: Format a Date as "Mar 2025"
function formatMonthYear(date) {
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
}

// 3) Group monthly rows by calendar year (for multi-year loans)
function groupByYear(monthlySchedule) {
  // We'll accumulate sums per year
  const yearMap = new Map();

  monthlySchedule.forEach((row) => {
    const y = row.rawDate.getFullYear();
    if (!yearMap.has(y)) {
      yearMap.set(y, {
        year: y,
        principal: 0,
        interest: 0,
        prepayment: 0,
        taxes: 0,
        insurance: 0,
        maintenance: 0,
        balance: 0,
        percentagePaid: 0,
      });
    }
    const obj = yearMap.get(y);
    obj.principal += row.principal;
    obj.interest += row.interest;
    obj.prepayment += row.prepayment;
    obj.taxes += row.taxes;
    obj.insurance += row.insurance;
    obj.maintenance += row.maintenance;
    // Each month’s final balance overwrites the old one; so at the end of the year, we have the last monthly balance
    obj.balance = row.balance;
    obj.percentagePaid = row.percentagePaid;
  });

  // Convert Map to sorted array by year
  const sorted = Array.from(yearMap.values()).sort((a, b) => a.year - b.year);
  return sorted;
}

const currentYearMonth = new Date().toISOString().slice(0, 7);

const HomeLoanEMICalculator = () => {
  const navigate = useNavigate();

  // --------------------
  // Existing States
  // --------------------
  const [homeValue, setHomeValue] = useState(5000000);
  const [downPayment, setDownPayment] = useState(20);
  const [loanInsurance, setLoanInsurance] = useState(0);
  const [interestRate, setInterestRate] = useState(9);
  const [loanFees, setLoanFees] = useState(0.25);
  const [startMonth, setStartMonth] = useState(currentYearMonth);

  const [propertyTax, setPropertyTax] = useState(0.25);
  const [homeInsurance, setHomeInsurance] = useState(0.05);
  const [maintenance, setMaintenance] = useState(2500);

  // Partial Prepayments
  const [monthlyPrepayment, setMonthlyPrepayment] = useState(0);
  const [yearlyPrepayment, setYearlyPrepayment] = useState(0);
  const [quarterlyPrepayment, setQuarterlyPrepayment] = useState(0);
  const [oneTimePrepayment, setOneTimePrepayment] = useState(0);
  const [downPaymentMode, setDownPaymentMode] = useState("%");

  const [loanTenure, setLoanTenure] = useState(20); // default to 20 years
  const [isYears, setIsYears] = useState(true);
  const [oneTimeExpense, setOneTimeExpense] = useState(10);
  const [isPercentage, setIsPercentage] = useState(false);

  // For toggling units on fees, taxes, etc.
  const [loanFeesUnit, setLoanFeesUnit] = useState("₹");
  const [propertyTaxUnit, setPropertyTaxUnit] = useState("₹");
  const [homeInsuranceUnit, setHomeInsuranceUnit] = useState("₹");
  const [toggled, setToggled] = useState(false);


  const [monthlyStart, setMonthlyStart] = useState(currentYearMonth);
  const [quarterlyStart, setQuarterlyStart] = useState(currentYearMonth);
  const [oneTimeStart, setOneTimeStart] = useState(currentYearMonth);

  const [yearlyStart, setyearlyStart] = useState(currentYearMonth);


  const totalAmount = 400000
  // --------------------
  // Event Handlers
  // --------------------
  const toggleLoanFees = (newUnit) => {
    if (newUnit === "%" && loanFeesUnit !== "%") {
      setLoanFees((loanFees / 100).toFixed(4));
      setLoanFeesUnit("%");
    } else if (newUnit === "₹" && loanFeesUnit !== "₹") {
      setLoanFees(Math.round(loanFees * 100));
      setLoanFeesUnit("₹");
    }
  };

  const handleDownPaymentChange = (e) => {
    let value = Number(e.target.value);
    if (downPaymentMode === "%") {
      setDownPayment(value);
    } else {
      setDownPayment((value / homeValue) * 100);
    }
  };

  const toggleUnitOneTime = (unit) => {
    if (unit === "%" && !isPercentage) {
      setOneTimeExpense((oneTimeExpense / totalAmount).toFixed(4));
      setIsPercentage(true);
    } else if (unit === "₹" && isPercentage) {
      setOneTimeExpense(Math.round(oneTimeExpense * totalAmount));
      setIsPercentage(false);
    }
  };

  const toggleUnitLoanTenure = (unit) => {
    if (unit === "Yr" && !isYears) {
      setLoanTenure(Math.max(1, Math.floor(loanTenure / 12)));
      setIsYears(true);
    } else if (unit === "Mo" && isYears) {
      setLoanTenure(loanTenure * 12);
      setIsYears(false);
    }
  };

  const toggleHomeInsurance = (newUnit) => {
    if (newUnit === "%" && homeInsuranceUnit !== "%") {
      setHomeInsurance((homeInsurance / 100).toFixed(4));
      setHomeInsuranceUnit("%");
    } else if (newUnit === "₹" && homeInsuranceUnit !== "₹") {
      setHomeInsurance(Math.round(homeInsurance * 100));
      setHomeInsuranceUnit("₹");
    }
  };

  const togglePropertyTax = (newUnit) => {
    if (newUnit === "%" && propertyTaxUnit !== "%") {
      setPropertyTax((propertyTax / 100).toFixed(4));
      setPropertyTaxUnit("%");
    } else if (newUnit === "₹" && propertyTaxUnit !== "₹") {
      setPropertyTax(Math.round(propertyTax * 100));
      setPropertyTaxUnit("₹");
    }
  };

  const clearPrePayment = () => {
    setToggled(!toggled)
    setOneTimePrepayment(0)
    setQuarterlyPrepayment(0)
    setYearlyPrepayment(0)
    setMonthlyPrepayment(0)
  }

  // --------------------
  // Basic Calculations
  // --------------------
  const loanAmount = homeValue - (downPayment / 100) * homeValue + loanInsurance;
  const monthlyRate = interestRate / 100 / 12;
  const numberOfPayments = isYears ? loanTenure * 12 : loanTenure;

  // Standard EMI (no partial prepayments)
  const emi =
    (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) /
    (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

  // --------------------
  // Build Full Monthly Schedule
  // --------------------
  function simulateMonthlySchedule() {
    const schedule = [];
    let totalPaid = 0; // Track total amount paid

    // Parse `startMonth` as a date
    const startYear = Number(startMonth.split("-")[0]);
    const startMo = Number(startMonth.split("-")[1]) - 1;
    let currentDate = new Date(startYear, startMo, 1);
    let remaining = loanAmount;

    // Convert prepayment start months (YYYY-MM) to Date objects
    const monthlyStartDate = monthlyStart ? new Date(Number(monthlyStart.split("-")[0]), Number(monthlyStart.split("-")[1]) - 1, 1) : null;
    const quarterlyStartDate = quarterlyStart ? new Date(Number(quarterlyStart.split("-")[0]), Number(quarterlyStart.split("-")[1]) - 1, 1) : null;
    const oneTimeStartDate = oneTimeStart ? new Date(Number(oneTimeStart.split("-")[0]), Number(oneTimeStart.split("-")[1]) - 1, 1) : null;
    const yearlyStartDate = yearlyStart ? new Date(Number(yearlyStart.split("-")[0]), Number(yearlyStart.split("-")[1]) - 1, 1) : null;

    for (let i = 1; i <= numberOfPayments; i++) {
      if (remaining <= 0.001) break; // fully paid

      const interestForMonth = remaining * monthlyRate;
      let scheduledPayment = emi;
      if (remaining + interestForMonth < emi) {
        scheduledPayment = remaining + interestForMonth;
      }
      const principalPayment = scheduledPayment - interestForMonth;

      // Prepayments based on selected start months
      let prepayment = 0;

      if (monthlyStartDate && currentDate >= monthlyStartDate) {
        prepayment += monthlyPrepayment;
      }
      if (oneTimeStartDate && currentDate.getFullYear() === oneTimeStartDate.getFullYear() &&
        currentDate.getMonth() === oneTimeStartDate.getMonth()) {
        prepayment += oneTimePrepayment;
      }
      if (quarterlyStartDate && currentDate >= quarterlyStartDate &&
        (currentDate.getMonth() - quarterlyStartDate.getMonth()) % 3 === 0) {
        prepayment += quarterlyPrepayment;
      }
      if (yearlyStartDate && currentDate >= yearlyStartDate &&
        currentDate.getMonth() === yearlyStartDate.getMonth()) {
        prepayment += yearlyPrepayment;
      }

      if (principalPayment + prepayment > remaining) {
        prepayment = Math.max(0, remaining - principalPayment);
      }

      // Taxes, Insurance, Maintenance
      const monthlyTax = (propertyTax * homeValue) / 100 / 12;
      const monthlyInsurance = (homeInsurance * homeValue) / 100 / 12;
      const monthlyMaint = maintenance;

      totalPaid += principalPayment + prepayment; // Track cumulative paid amount
      const percentagePaid = (totalPaid / loanAmount) * 100; // Calculate percentage of loan paid

      schedule.push({
        rawDate: new Date(currentDate), // keep full date
        dateLabel: formatMonthYear(currentDate),
        principal: principalPayment,
        interest: interestForMonth,
        prepayment,
        taxes: monthlyTax,
        insurance: monthlyInsurance,
        maintenance: monthlyMaint,
        balance: Math.max(remaining - principalPayment - prepayment, 0),
        percentagePaid: percentagePaid.toFixed(2) + "%" // Show percentage paid
      });

      remaining -= principalPayment + prepayment;
      // Move to next month
      currentDate = addMonths(currentDate, 1);
    }
    return schedule;
  }


  const monthlySchedule = simulateMonthlySchedule();


  // Summaries
  const totalInterest = monthlySchedule.reduce((sum, m) => sum + m.interest, 0);
  const totalPrepayments = monthlySchedule.reduce((sum, m) => sum + m.prepayment, 0);
  const totalEMIPaid = monthlySchedule.reduce((sum, m) => sum + (m.principal + m.interest), 0);
  const effectiveMonths = monthlySchedule.length;
  const totalPayment = totalEMIPaid + totalPrepayments;

  // --------------------
  // Prepare Data for Charts
  // --------------------
  let barData = [];
  let xAxisCategories = [];
  let chartTitleRange = "";

  if (numberOfPayments > 12) {
    // Group by year
    const yearly = groupByYear(monthlySchedule);
    barData = yearly;
    xAxisCategories = yearly.map((y) => String(y.year));
    const startYear = yearly[0]?.year || "";
    const endYear = yearly[yearly.length - 1]?.year || "";
    chartTitleRange = `${startYear} - ${endYear}`;
  } else {
    // Use monthly data
    barData = monthlySchedule;
    xAxisCategories = monthlySchedule.map((m) => m.dateLabel);
    const startLabel = monthlySchedule[0]?.dateLabel || "";
    const endLabel = monthlySchedule[monthlySchedule.length - 1]?.dateLabel || "";
    chartTitleRange = `${startLabel} - ${endLabel}`;
  }

  const principalSeries = barData.map((d) => Math.round(d.principal));
  const prepaySeries = barData.map((d) => Math.round(d.prepayment));
  const interestSeries = barData.map((d) => Math.round(d.interest));
  const otherSeries = barData.map((d) =>
    Math.round(d.taxes + d.insurance + d.maintenance)
  );
  const balanceSeries = barData.map((d) => Math.round(d.balance));

  // 1) Pie Chart
  const pieChartOptions = {
    chart: {
      type: "pie",
      options3d: { enabled: true, alpha: 45 },
      height: 260,
    },
    title: {
      enabled: false,
      text: "Payments Chart",
      align: "center",
      style: { color: "#005555", fontSize: "18px" },
    },
    plotOptions: {
      pie: {
        innerSize: "70%",
        depth: 45,
        dataLabels: { enabled: false },
      },
    },
    legend: {
      enabled: false,
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
      itemStyle: { fontSize: "14px", color: "#333" },
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 0, // Square legend icons like in the image
    },
    series: [
      {
        name: "Amount",
        data: [
          { name: "Principal", y: loanAmount, color: "#4CAF50" },
          { name: "Interest", y: totalInterest, color: "#FF5733" },
          { name: "Prepayment", y: totalPrepayments, color: "#FFD700" },
          {
            name: "Down Payment, Fees & One-time Expenses",
            y: oneTimeExpense,
            color: "#0175FF",
          },
          {
            name: "Taxes, Home Insurance & Maintenance",
            y:
              ((propertyTax + homeInsurance) * homeValue) / 100 * (effectiveMonths / 12) +
              maintenance * effectiveMonths,
            color: "#900C3F",
          },
        ],
        showInLegend: true,
      },
    ],
  };

  // 2) Stacked Column + Line Chart
  const barChartOptions = {
    chart: {
      type: "column", height: 520, backgroundColor: "#F3F9FF", options3d: {
        enabled: true,
        alpha: 45,
      },
    },
    title: {
      text: `Home Loan Payment Schedule (${chartTitleRange})`,
      align: "center",
      style: { color: "#00204A", fontSize: "18px" },
    },
    xAxis: {
      categories: xAxisCategories,
      crosshair: true,
    },
    yAxis: [
      {
        min: 0,
        title: { text: "Payment (₹)" },
        labels: {
          formatter: function () {
            return `₹ ${Highcharts.numberFormat(this.value, 0, ".", ",")}`;
          },
        },
      },
      {
        title: { text: "Loan Balance (₹)" },
        opposite: true,
        labels: {
          formatter: function () {
            return `₹ ${Highcharts.numberFormat(this.value, 0, ".", ",")}`;
          },
        },
      },
    ],
    tooltip: { shared: true },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: [
      {
        name: "Interest",
        data: interestSeries,
        stack: "payment",
        color: "#FF5733",
      },
      {
        name: "Principal",
        data: principalSeries,
        stack: "payment",
        color: "#4CAF50",
      },
      {
        name: "Prepayments",
        data: prepaySeries,
        stack: "payment",
        color: "#FFD700",
      },
      {
        name: "Taxes, Home Insurance & Maintenance",
        data: otherSeries,
        stack: "payment",
        color: "#900C3F",
      },
      {
        type: "spline",
        name: "Balance",
        data: balanceSeries,
        yAxis: 1,
        color: "#914611",
        marker: { enabled: true },
      },
    ],
  };

  // ---------------------------------------------
  // EXPANDABLE TABLE (Year -> Months)
  // ---------------------------------------------
  // 1) State to track which years are expanded
  const [expandedYears, setExpandedYears] = useState([]);

  // 2) Toggle function
  const toggleYear = (year) => {
    setExpandedYears((prev) =>
      prev.includes(year) ? prev.filter((y) => y !== year) : [...prev, year]
    );
  };


  // 3) We'll reuse the yearly data from groupByYear
  const yearlyData = groupByYear(monthlySchedule);

  // Helper to format large numbers with commas
  const fmt = (num) => num ? '₹' + num.toLocaleString("en-IN", { maximumFractionDigits: 0 }) : '₹ 0';

  const fmtDatePickerValue = (date_value) => {
    const date = new Date(date_value);
    const formatter = new Intl.DateTimeFormat("en-CA", { year: "numeric", month: "2-digit" });
    return formatter.format(date)
  }

  return (
    <div className="section-properties container pb-12">
      {/* Top Navigation Section */}
      <div className="w-full flex justify-between items-center">
        <p
          className="font-bold text-primary text-xl cursor-pointer my-4"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Home Loan EMI Calculator
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Left / Main Column */}
        <div className="shadow-sm p-6 bg-[#F3F9FF] rounded-lg border border-gray-300 md:col-span-2">
          <h2 className="text-lg font-semibold">Home Loan Details</h2>
          <hr />
          {/* ----------------- Loan Input Fields ----------------- */}
          <div className="my-4 grid grid-cols-2 sm:grid-cols-3 gap-6">
            {/* Home Value */}
            <div>
              <label className="text-gray-700">Home Value</label>
              <div className="relative">
                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                  ₹
                </div>
                <input
                  type="number"
                  value={homeValue}
                  onChange={(e) => setHomeValue(Number(e.target.value))}
                  className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-6"
                />
              </div>
            </div>

            {/* Down Payment */}
            <div>
              <label className="text-gray-700">Margin OR Down Payment</label>
              <div className="relative">
                <input
                  type="number"
                  value={
                    downPaymentMode === "%"
                      ? downPayment
                      : ((downPayment / 100) * homeValue).toFixed(0)
                  }
                  onChange={handleDownPaymentChange}
                  className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-16"
                />
                <div className="absolute inset-y-0 end-0 flex items-center pe-2">
                  <span
                    className={`px-2 py-1 text-sm ${downPaymentMode === "₹"
                      ? "font-bold text-blue-600"
                      : "text-gray-500"
                      }`}
                    onClick={() => setDownPaymentMode("₹")}
                  >
                    ₹
                  </span>
                  <span className="text-gray-400">/</span>
                  <span
                    className={`px-2 py-1 text-sm ${downPaymentMode === "%"
                      ? "font-bold text-blue-600"
                      : "text-gray-500"
                      }`}
                    onClick={() => setDownPaymentMode("%")}
                  >
                    %
                  </span>
                </div>
              </div>
              <label className="text-xs">
                Selected Unit: <strong>{downPaymentMode}</strong>
              </label>
            </div>

            {/* Loan Insurance */}
            <div>
              <label className="text-gray-700">Loan Insurance</label>
              <div className="relative">
                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                  ₹
                </div>
                <input
                  type="number"
                  value={loanInsurance}
                  onChange={(e) => setLoanInsurance(Number(e.target.value))}
                  className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-6"
                />
              </div>
            </div>

            {/* Loan Amount */}
            <div>
              <label className="text-gray-700">Loan Amount</label>
              <div className="relative">
                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                  ₹
                </div>
                <input
                  type="text"
                  value={loanAmount.toLocaleString()}
                  readOnly
                  className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-6"
                />
              </div>
            </div>

            {/* Interest Rate */}
            <div>
              <label className="text-gray-700">Interest Rate</label>
              <div className="relative">
                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                  %
                </div>
                <input
                  type="number"
                  value={interestRate}
                  onChange={(e) => setInterestRate(Number(e.target.value))}
                  className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-6"
                />
              </div>
            </div>

            {/* Loan Tenure */}
            <div>
              <label className="text-gray-700">Loan Tenure</label>
              <div className="relative">
                <input
                  type="number"
                  value={loanTenure}
                  onChange={(e) => setLoanTenure(Number(e.target.value))}
                  className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-16"
                />
                <div className="absolute inset-y-0 end-0 flex items-center pe-2">
                  <span
                    className={`px-2 cursor-pointer ${!isYears ? "text-blue-600 font-bold" : "text-gray-500"
                      }`}
                    onClick={() => toggleUnitLoanTenure("Mo")}
                  >
                    Mo
                  </span>
                  <span className="text-gray-400">/</span>
                  <span
                    className={`px-2 cursor-pointer ${isYears ? "text-blue-600 font-bold" : "text-gray-500"
                      }`}
                    onClick={() => toggleUnitLoanTenure("Yr")}
                  >
                    Yr
                  </span>
                </div>
              </div>
            </div>

            {/* Loan Fees & Charges */}
            <div>
              <label className="text-gray-700">Loan Fees & Charges</label>
              <div className="relative">
                <div className="absolute inset-y-0 end-0 flex items-center pe-2">
                  <span
                    className={`px-2 py-1 text-sm ${loanFeesUnit === "₹" ? "font-bold text-blue-600" : "text-gray-500"
                      }`}
                    onClick={() => toggleLoanFees("₹")}
                  >
                    ₹
                  </span>
                  <span className="text-gray-400">/</span>
                  <span
                    className={`px-2 py-1 text-sm ${loanFeesUnit === "%" ? "font-bold text-blue-600" : "text-gray-500"
                      }`}
                    onClick={() => toggleLoanFees("%")}
                  >
                    %
                  </span>
                </div>
                <input
                  type="number"
                  value={loanFees}
                  onChange={(e) => setLoanFees(e.target.value)}
                  className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-16"
                  placeholder={`Enter amount in ${loanFeesUnit}`}
                />
              </div>
              <label className="text-xs">
                Selected Unit: <strong>{loanFeesUnit}</strong>
              </label>
            </div>

            {/* Start Month & Year */}
            <div>
              <label className="text-gray-700">Start Month & Year</label>
              <div className="relative">
                <DatePicker
                  selected={startMonth}
                  onChange={(date) => setStartMonth(fmtDatePickerValue(date))}
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                  icon={faCalendar}
                  wrapperClassName={'w-full'}
                  className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full p-2"
                />
                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                  <FontAwesomeIcon icon={faCalendar} />
                </div>

              </div>
            </div>
          </div>

          {/* Homeowner Expenses */}
          <h2 className="text-lg font-semibold">Homeowner Expenses</h2>
          <hr />
          <div className="my-4 grid grid-cols-2 sm:grid-cols-3 gap-6">
            {/* One-time Expenses */}
            <div>
              <label className="text-gray-700">One-time Expenses</label>
              <div className="relative">
                <input
                  type="number"
                  value={oneTimeExpense}
                  onChange={(e) => setOneTimeExpense(Number(e.target.value))}
                  className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-16"
                />
                <div className="absolute inset-y-0 end-0 flex items-center pe-2">
                  <span
                    className={`px-2 cursor-pointer ${!isPercentage ? "text-blue-600 font-bold" : "text-gray-500"
                      }`}
                    onClick={() => toggleUnitOneTime("₹")}
                  >
                    ₹
                  </span>
                  <span className="text-gray-400">/</span>
                  <span
                    className={`px-2 cursor-pointer ${isPercentage ? "text-blue-600 font-bold" : "text-gray-500"
                      }`}
                    onClick={() => toggleUnitOneTime("%")}
                  >
                    %
                  </span>
                </div>
              </div>
              <label className="text-xs">
                Selected Unit: <strong>{isPercentage ? '%' : '₹'}</strong>
              </label>
            </div>

            {/* Property Taxes */}
            <div>
              <label className="text-gray-700">Property Taxes / Year</label>
              <div className="relative">
                <div className="absolute inset-y-0 end-0 flex items-center pe-2">
                  <span
                    className={`px-2 py-1 text-sm ${propertyTaxUnit === "₹" ? "font-bold text-blue-600" : "text-gray-500"
                      }`}
                    onClick={() => togglePropertyTax("₹")}
                  >
                    ₹
                  </span>
                  <span className="text-gray-400">/</span>
                  <span
                    className={`px-2 py-1 text-sm ${propertyTaxUnit === "%" ? "font-bold text-blue-600" : "text-gray-500"
                      }`}
                    onClick={() => togglePropertyTax("%")}
                  >
                    %
                  </span>
                </div>
                <input
                  type="number"
                  value={propertyTax}
                  onChange={(e) => setPropertyTax(e.target.value)}
                  className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-16"
                  placeholder={`Enter amount in ${propertyTaxUnit}`}
                />
              </div>
              <label className="text-xs">
                Selected Unit: <strong>{propertyTaxUnit}</strong>
              </label>
            </div>

            {/* Home Insurance */}
            <div>
              <label className="text-gray-700">Home Insurance / Year</label>
              <div className="relative">
                <div className="absolute inset-y-0 end-0 flex items-center pe-2">
                  <span
                    className={`px-2 cursor-pointer ${!homeInsuranceUnit ? "text-blue-600 font-bold" : "text-gray-500"
                      }`}
                    onClick={() => toggleHomeInsurance("₹")}
                  >
                    ₹
                  </span>
                  <span className="text-gray-400">/</span>
                  <span
                    className={`px-2 cursor-pointer ${homeInsuranceUnit ? "text-blue-600 font-bold" : "text-gray-500"
                      }`}
                    onClick={() => toggleHomeInsurance("%")}
                  >
                    %
                  </span>
                </div>
                <input
                  type="number"
                  value={homeInsurance}
                  onChange={(e) => setHomeInsurance(e.target.value)}
                  className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-16"
                  placeholder={`Enter amount in ${homeInsuranceUnit}`}
                />
              </div>
              <label className="text-xs">
                Selected Unit: <strong>{homeInsuranceUnit}</strong>
              </label>
            </div>

            {/* Maintenance */}
            <div>
              <label className="text-gray-700">Maintenance Expenses / Month</label>
              <div className="relative">
                <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                  ₹
                </div>
                <input
                  type="number"
                  value={maintenance}
                  onChange={(e) => setMaintenance(Number(e.target.value))}
                  className="bg-[#DCEFF9] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-6"
                />
              </div>
            </div>
          </div>

          {/* Partial Prepayments (Toggle) */}
          {toggled && (

            <div className="pt-4">
              {/* Prepayment Inputs */}
              <h2 className="text-lg font-semibold">Partial Prepayments — Amount and Start Date</h2>
              <hr></hr>
              <div className="my-4 grid grid-cols-2 sm:grid-cols-4 gap-6">

                {/* Monthly Prepayment */}
                <div>
                  <label className="text-gray-700">Monthly</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                      ₹
                    </div>
                    <input
                      type="number"
                      value={monthlyPrepayment}
                      onChange={(e) => setMonthlyPrepayment(Number(e.target.value))}
                      className="bg-[#fff] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-6"
                    />
                  </div>
                  <label className="text-gray-700 mt-4">Starting from</label>
                  <div className="relative">
                    <DatePicker
                      selected={monthlyStart}
                      onChange={(date) => setMonthlyStart(fmtDatePickerValue(date))}
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                      icon={faCalendar}
                      wrapperClassName={'w-full'}
                      className="bg-[#fff] border border-gray-300 text-gray-900 text-sm rounded block w-full p-2"
                    />
                    <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                      <FontAwesomeIcon icon={faCalendar} />
                    </div>

                  </div>
                </div>

                {/* Quarterly Prepayment */}
                <div>
                  <label className="text-gray-700">Quarterly</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                      ₹
                    </div>
                    <input
                      type="number"
                      value={quarterlyPrepayment}
                      onChange={(e) => setQuarterlyPrepayment(Number(e.target.value))}
                      className="bg-[#fff] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-6"
                    />
                  </div>
                  <label className="text-gray-700 mt-4">Starting from</label>
                  <div className="relative">
                    <DatePicker
                      selected={quarterlyStart}
                      onChange={(date) => setQuarterlyStart(fmtDatePickerValue(date))}
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                      icon={faCalendar}
                      wrapperClassName={'w-full'}
                      className="bg-[#fff] border border-gray-300 text-gray-900 text-sm rounded block w-full p-2"
                    />
                    <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                      <FontAwesomeIcon icon={faCalendar} />
                    </div>

                  </div>
                </div>

                {/* Yearly Prepayment */}
                <div>
                  <label className="text-gray-700">Yearly</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                      ₹
                    </div>
                    <input
                      type="number"
                      value={yearlyPrepayment}
                      onChange={(e) => setYearlyPrepayment(Number(e.target.value))}
                      className="bg-[#fff] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-6"
                    />
                  </div>
                  <label className="text-gray-700 mt-4">Starting from</label>
                  <div className="relative">
                    <DatePicker
                      selected={yearlyStart}
                      onChange={(date) => setyearlyStart(fmtDatePickerValue(date))}
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                      icon={faCalendar}
                      wrapperClassName={'w-full'}
                      className="bg-[#fff] border border-gray-300 text-gray-900 text-sm rounded block w-full p-2"
                    />
                    <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                      <FontAwesomeIcon icon={faCalendar} />
                    </div>
                  </div>

                </div>

                {/* One-Time Prepayment */}
                <div>
                  <label className="text-gray-700">One-Time</label>
                  <div className="relative">
                    <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                      ₹
                    </div>
                    <input
                      type="number"
                      value={oneTimePrepayment}
                      onChange={(e) => setOneTimePrepayment(Number(e.target.value))}
                      className="bg-[#fff] border border-gray-300 text-gray-900 text-sm rounded block w-full py-2 pl-2 pr-6"
                    />
                  </div>
                  <label className="text-gray-700 mt-4">In the month of</label>
                  <div className="relative">
                    <DatePicker
                      selected={oneTimeStart}
                      onChange={(date) => setOneTimeStart(fmtDatePickerValue(date))}
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                      icon={faCalendar}
                      wrapperClassName={'w-full'}
                      className="bg-[#fff] border border-gray-300 text-gray-900 text-sm rounded block w-full p-2"
                    />
                    <div className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none">
                      <FontAwesomeIcon icon={faCalendar} />
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>

          )}

          {/* Toggle Button */}
          <button
            onClick={() => clearPrePayment()}
            className="flex items-center space-x-2 mx-auto px-4 py-2 bg-[#2563EB] text-white font-medium rounded-md shadow-md transition-all"
          >
            <span className="w-6 h-6 bg-white flex items-center justify-center rounded-full">
              <FontAwesomeIcon icon={toggled ? faMinus : faPlus} className="text-[#2563EB]" />
            </span>
            <span>{toggled ? "Remove Prepayments" : "Add Prepayments"}</span>
          </button>
          <hr />

          {/* Payment Breakdown Table */}
          <div className="bg-[#DCEFF9] rounded-md text-gray-800 my-4 border border-gray-300">
            <h2 className="text-lg font-semibold mx-4 my-3">Payment Breakdown</h2>
            <table className="w-full border-collapse">
              <tbody>
                <tr className="border-t border-dashed border-gray-400">
                  <td className="py-2 px-4">Principal & Interest (EMI)</td>
                  <td className="text-right py-2 px-4">₹ {emi.toFixed(0)}</td>
                </tr>
                <tr className="border-t border-dashed border-gray-400">
                  <td className="py-2 px-4">Property Taxes (per month)</td>
                  <td className="text-right py-2 px-4">₹ {((propertyTax * homeValue) / 100 / 12).toFixed(0)}</td>
                </tr>
                <tr className="border-t border-dashed border-gray-400">
                  <td className="py-2 px-4">Home Insurance (per month)</td>
                  <td className="text-right py-2 px-4">₹ {((homeInsurance * homeValue) / 100 / 12).toFixed(0)}</td>
                </tr>
                <tr className="border-t border-dashed border-gray-400">
                  <td className="py-2 px-4">Maintenance Expenses</td>
                  <td className="text-right py-2 px-4">₹ {maintenance}</td>
                </tr>
                <tr className="border-t border-dashed border-gray-400">
                  <td className="py-2 px-4">Total Prepayments</td>
                  <td className="text-right py-2 px-4">₹ {totalPrepayments.toFixed(0)}</td>
                </tr>
                <tr className="border-t border-dashed border-gray-400">
                  <td className="py-2 px-4">Total EMI Payments</td>
                  <td className="text-right py-2 px-4">₹ {totalEMIPaid.toFixed(0)}</td>
                </tr>
                <tr className="border-t border-dashed border-gray-400">
                  <td className="py-2 px-4">Effective Loan Tenure</td>
                  <td className="text-right py-2 px-4">
                    {effectiveMonths} months ({(effectiveMonths / 12).toFixed(1)} years)
                  </td>
                </tr>
                <tr style={{ borderTop: 'double rgb(135, 155, 165)' }} className="border-t border-gray-400 font-bold">
                  <td className="py-3 px-4">Total Monthly Payment</td>
                  <td className="text-right py-2 px-4">
                    ₹{" "}
                    {(
                      emi +
                      (propertyTax * homeValue) / 100 / 12 +
                      (homeInsurance * homeValue) / 100 / 12 +
                      maintenance
                    ).toFixed(0)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Pie Chart */}
          <div className="bg-white rounded-md text-gray-800 my-4 border border-gray-300">
            <table className="w-full border-collapse">
              <tbody>
                {/* Row for Pie Chart - rowspan applied */}
                <tr>
                  <td className="py-2 px-4" rowSpan="5">
                    <HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
                  </td>
                  <td className="border-b border-dashed border-gray-400 h-14"> <span className="inline-block w-4 h-4 mr-2 rounded-sm bg-[#0175FF]"></span>Down Payment, Fees & One-time Expenses</td>
                  <td className="text-right px-4 border-b border-dashed border-gray-400">
                    ₹ {(oneTimeExpense).toFixed(0)}
                  </td>
                </tr>
                <tr>
                  <td className="border-b border-dashed border-gray-400 h-14"> <span className="inline-block w-4 h-4 mr-2 rounded-sm bg-[#4CAF50]"></span>Principal</td>
                  <td className="text-right px-4 border-b border-dashed border-gray-400">
                    ₹ {(loanAmount).toFixed(0)}
                  </td>
                </tr>

                <tr>
                  <td className="border-b border-dashed border-gray-400 h-14"> <span className="inline-block w-4 h-4 mr-2 rounded-sm bg-[#FFD700]"></span>Prepayments</td>
                  <td className="text-right px-4 border-b border-dashed border-gray-400">
                    ₹ {totalPrepayments.toFixed(0)}
                  </td>
                </tr>
                <tr>
                  <td className="border-b border-dashed border-gray-400 h-14"> <span className="inline-block w-4 h-4 mr-2 rounded-sm bg-[#FF5733]"></span>Interest</td>
                  <td className="text-right px-4 border-b border-dashed border-gray-400">
                    ₹ {totalInterest.toFixed(0)}
                  </td>
                </tr>
                <tr>
                  <td className="border-b border-dashed border-gray-400 h-14"> <span className="inline-block w-4 h-4 mr-2 rounded-sm bg-[#900C3F]"></span>Taxes, Home Insurance & Maintenance</td>
                  <td className="text-right px-4 border-b border-dashed border-gray-400">
                    ₹ {(((propertyTax + homeInsurance) * homeValue) / 100 * (effectiveMonths / 12) +
                      maintenance * effectiveMonths).toFixed(0)}
                  </td>
                </tr>

                {/* Total Monthly Payment Row with double border */}
                <tr style={{ borderTop: 'double rgb(135, 155, 165)' }} className="border-t border-gray-400 font-bold">
                  <td className="py-3 px-4">Total of all Payment</td>
                  <td colSpan="5" className="text-right py-2 px-4">
                    ₹ {(totalPayment + oneTimeExpense).toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>


        <div className="bg-[#F3F9FF] border border-gray-300 rounded-lg" style={{ height: 'fit-content' }}>
          <div className="text-gray-700 text-base font-semibold p-3">
            Popular Calculators
          </div>
          <div className="flex flex-col divide-y divide-gray-300">
            {[
              { name: "SIP Calculator", link: "/sip-calculator" },
              { name: "PPF Calculator", link: "/ppf-calculator" },
              { name: "EPF Calculator", link: "/epf-calculator" },
              { name: "Lumpsum Calculator", link: "/lumpsum-calculator" },
              { name: "FD Calculator", link: "/fd-calculator" },
              { name: "SWP Calculator", link: "/swp-calculator" },
              {
                name: "Mutual Fund Returns Calculator",
                link: "/mutual-fund-calculator",
              },
              { name: "RD Calculator", link: "/rd-calculator" },
              { name: "SSY Calculator", link: "/ssy-calculator" },
              { name: "Home Loan Calculator", link: "/home-loan-calculator" },
            ].map((item, index) => (
              <a
                key={index}
                href={item.link}
                className="p-3 text-gray-600 text-sm hover:text-[#fff] hover:bg-[#005555]"
              >
                {item.name}
              </a>
            ))}
          </div>
        </div>


        <div className="md:col-span-3">
          {/* Stacked Column + Line Chart */}
          <div className="p-6 bg-[#F3F9FF] rounded-md text-gray-800 border border-gray-300">
            <HighchartsReact highcharts={Highcharts} options={barChartOptions} />
          </div>


          {/* ---------------------------
            EXPANDABLE TABLE
          --------------------------- */}
          <div className="my-8 overflow-x-auto rounded-lg">
            <table className="table-auto border-collapse rounded-lg border border-gray-300" style={{ width: '100%' }}>
              <thead className="bg-[#DCEFF9]">
                <tr className="text-center h-14">
                  <th className="border p-2">Year/Month</th>
                  <th className="border p-2 bg-[#4CAF50] text-[#F3F3F3]">Principal (A)</th>
                  <th className="border p-2 bg-[#ffAF50] text-[#F3F3F3]">Prepayment (B)</th>
                  <th className="border p-2 bg-[#FF5733] text-[#F3F3F3]">Interest (C)</th>
                  <th className="border p-2 bg-[#900C3F] text-[#F3F3F3]">Taxes & Maintenance (D)</th>
                  <th className="border p-2">Total Payment (A+B+C+D)</th>
                  <th className="border p-2 bg-[#914611] text-[#F3F3F3]">Balance</th>
                  <th className="border p-2">Loan Paid to Date</th>
                  <th className="border p-2">Paid (%)</th>
                </tr>
              </thead>
              <tbody className="text-right">
                {yearlyData.map((yr) => {
                  const monthlyRows = monthlySchedule.filter((m) => m.rawDate.getFullYear() === yr.year);

                  return (
                    <React.Fragment key={yr.year}>
                      {/* YEAR ROW */}
                      <tr className="bg-gray-100 cursor-pointer" onClick={() => toggleYear(yr.year)}>
                        <td className="border p-2 font-semibold text-center">
                          <span className="mr-2">
                            {expandedYears.includes(yr.year) ? (
                              <FontAwesomeIcon icon={faMinus} />
                            ) : (
                              <FontAwesomeIcon icon={faPlus} />
                            )}
                          </span>
                          {yr.year}
                        </td>
                        <td className="border p-2">{fmt(yr.principal)}</td>
                        <td className="border p-2">{fmt(yr.prepayment)}</td>
                        <td className="border p-2">{fmt(yr.interest)}</td>
                        <td className="border p-2">{fmt(yr.taxes + yr.insurance + yr.maintenance)}</td>
                        <td className="border p-2">{fmt(yr.principal + yr.interest + yr.taxes)}</td>
                        <td className="border p-2">{fmt(yr.balance)}</td>
                        <td className="border p-2">{fmt(loanAmount - yr.balance)}</td>
                        <td className="border p-2">{(yr.percentagePaid)}</td>
                      </tr>

                      {/* MONTHLY ROWS */}
                      {expandedYears.includes(yr.year) &&
                        monthlyRows.map((m, idx) => {

                          return (
                            <tr className="bg-gray-200" key={idx}>
                              <td className="border p-2 text-center font-bold">{m.dateLabel}</td>
                              <td className="border p-2">{fmt(m.principal)}</td>
                              <td className="border p-2">{fmt(m.prepayment)}</td>
                              <td className="border p-2">{fmt(m.interest)}</td>
                              <td className="border p-2">{fmt(m.taxes + m.insurance + m.maintenance)}</td>
                              <td className="border p-2">{fmt(m.principal + m.interest + m.taxes + m.insurance + m.maintenance + m.prepayment)}</td>
                              <td className="border p-2">{fmt(m.balance)}</td>
                              <td className="border p-2">{fmt(loanAmount - m.balance)}</td>
                              <td className="border p-2">{(m.percentagePaid)}</td>
                            </tr>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* END of Expandable Table */}
        </div>

        <div className="md:col-span-3">
          <h2 className="text-lg font-semibold mb-2">
            How to Use Home Loan EMI Calculator?
          </h2>
          <p className="text-gray-700">
            Using our Home Loan EMI Calculator, you can calculate how much
            monthly and total expenditure you really incur by purchasing a home
            using your bank home loan.
          </p>
          <i>
            Home Loan Details includes single premium loan insurance and
            processing fees.
          </i>
          <br />
          <br />
          <ul className="list-disc pl-5 text-gray-700">
            <li>
              Home Value is the actual price of the home you purchased (i.e.,
              sale deed value).
            </li>
            <li>
              Down Payment, aka Margin, is the total money you paid to the
              seller or builder from your own pocket, entered either in Rupees
              or as a percentage of Home Value.
            </li>
            <li>
              Loan Insurance is the single premium amount, for the Home Loan
              Protection Plan (HLPP) OR Term Insurance Plan, that gets included
              in your home loan amount. If you want to guesstimate this amount,
              use the LIC premium calculator to calculate yearly premium for
              eTerm plan using your age, loan term and loan amount for Sum
              Assured. You then multiply this number by your loan tenure.
            </li>
            <li>
              Loan Amount is calculated as Home Value + Loan Insurance — Down
              Payment.
            </li>
            <li>
              Interest Rate is…the 10th wonder of the world! When its
              compounded, it ranks 8th.
            </li>
            <li>
              Loan Fees & Charges includes Processing Fees, Administrative
              Charges etc. along with service taxes, entered either in Rupees or
              as a percentage of Loan Amount.
            </li>
          </ul>
          <p className="text-gray-700">
            Homeowner Expenses can be entered either in Rupees or as a
            percentage of Home Value. You will not pay property taxes and home
            insurance premium each month, but it is included in the total
            monthly payment with the assumption that you are setting aside this
            amount (either through Recurring Deposit or some other means) every
            month. This will ensure that you will have the necessary money to
            make the payment once or twice a year. Please note that the
            recurring expenses will change over the lifetime of the home loan
            due to inflation and other factors. They will also continue beyond
            the home loan tenure. You should consider all these factors,
            especially when making a Rent vs. Buy decision.
          </p>

          <ul className="list-disc pl-5 text-gray-700">
            <li>
              One-time Expenses can include Registration Fees, Stamp Duty, bribe
              you paid to that pan chewing Govt Babu, money you spent on
              sprucing up your new home and performing a lavish house warming
              ceremony (after all, this is a great occasion for your spouse to
              show off in front of his/her friends & relatives).
            </li>
            <li>
              Property Taxes is the annual payment that you grudgingly make to
              your local municipal body.
            </li>
            <li>
              Home Insurance is the yearly premium you pay to insure your
              home—if it isn’t already covered by loan insurance plan.
            </li>
            <li>
              Monthly Maintenance Expenses is what you pay to keep your
              apartment shining, clean and ‘water-full’. If its an independent
              house, you can assume your annual maintenance expenditure to be
              about one percent of your home value. Divide this number by 12 to
              arrive at monthly maintenance amount.
            </li>
          </ul>
          <p className="text-gray-700">
            We wish you an affordable home loan and a great new home!
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeLoanEMICalculator;
